import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import * as jwtDecode from 'jwt-decode';
import {
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import {
  Account,
  AccountStatusEnum,
  AudienceType,
  AuthErrorResponse,
  AuthTokensResponse,
  AuthUsingVerificationRequest,
  Exception,
  LocaleEnum,
  PhoneVerificationRequest,
  PhoneVerificationResponse,
  RetryVerificationRequest,
  ValidateError,
} from 'viksi-models';

export type AuthAccountStateType =
  | 'guest'
  | 'signedIn'
  | 'signedUp'
  | 'signedOut';

export interface IAccountChangeResult {
  id: string;
  status: 'changed' | 'code_required' | 'error' | 'code_failed';
  model?: 'password' | 'new_password';
  error?: string;
}

/**
 * Ошибка авторизации
 */
export class AuthError extends Error implements Exception {
  public status = 405;
  public name = 'AuthError';

  constructor(public details: AuthErrorResponse, public message: string) {
    super(message);
    Object.setPrototypeOf(this, AuthError.prototype);
    // Error.captureStackTrace(this, Error);
  }
}

const logger = console;
const oneMinute = 60 * 1000;
const oneHour = 60 * oneMinute;

const access_token_key = environment.access_token_key || 'APP_AUTH_ACCESS';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  /* пользователь */
  private meSubject = new ReplaySubject<Account>(1);
  private me: Account;

  /** статус авторизации пользователя */
  public stateSubject = new ReplaySubject<AuthAccountStateType>(1);

  protected access_token_key = access_token_key;
  protected refresh_token_key = `${access_token_key}_REFRESH`;

  private inited = false;
  private authProcessing = false;
  private lastAccessToken: string;

  private recreateToken$$: Subscription;

  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private http: HttpClient) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public get isAuthenticated(): boolean {
    return this.me && this.me.status !== AccountStatusEnum.guest;
  }

  public get me$(): Observable<Account> {
    // @see https://rxjs.dev/guide/observable
    this.init(); // ленивая инициализация
    return this.meSubject.asObservable();
  }

  public get state$(): Observable<AuthAccountStateType> {
    this.init(); // ленивая инициализация
    return this.stateSubject.asObservable();
  }

  private init() {
    if (this.inited) {
      return;
    }
    this.inited = true;

    let account: Account;
    if (this.accessToken) {
      account = this.decodeAccessToken(this.accessToken);
      if (account) {
        this.lastAccessToken = this.accessToken;
        this.refreshAccount(account.id);
      }
    }
    if (!account) {
      this.recreateToken();
    }
  }

  public set accessToken(token: string) {
    if (token) {
      localStorage.setItem(this.access_token_key, token);
    } else {
      localStorage.removeItem(this.access_token_key);
    }
  }

  public get accessToken(): string {
    return localStorage.getItem(this.access_token_key);
  }

  public set refreshToken(token: string) {
    if (token) {
      localStorage.setItem(this.refresh_token_key, token);
    } else {
      localStorage.removeItem(this.refresh_token_key);
    }
  }

  public get refreshToken(): string {
    return localStorage.getItem(this.refresh_token_key);
  }

  public verifyPhoneAuth(
    mobile_phone: number
  ): Observable<PhoneVerificationResponse> {
    // ? PhoneVerificationErrorResponse
    const url = `${environment.account_url}/auth/verify/phone`;

    const values: PhoneVerificationRequest = {
      mobile_phone,
    };

    return this.http.post<any>(url, values, { observe: 'response' }).pipe(
      map((response) => response.body),
      catchError((err) => {
        if (err.status === 400 && err.error?.fields) {
          throw new ValidateError(err.error.fields, err.message);
        }
        throw new Error(err);
      })
    );
  }

  public verifyPhoneRetry(
    values: RetryVerificationRequest
  ): Observable<PhoneVerificationResponse> {
    const url = `${environment.account_url}/auth/verify/phone/retry`;

    return this.http.post<any>(url, values, { observe: 'response' }).pipe(
      map((response) => response.body),
      catchError((err) => {
        if (err.status === 400 && err.error?.fields) {
          throw new ValidateError(err.error.fields, err.message);
        }
        throw new Error(err);
      })
    );
  }

  public authUsingVerification(
    verification_id: string,
    verification_code: string
  ): Observable<AuthTokensResponse> {
    const url = `${environment.account_url}/auth/verification`;

    const values: AuthUsingVerificationRequest = {
      audience: this.audience,
      verification_id,
      verification_code,
    };

    return this.http
      .put<AuthTokensResponse>(url, values, { observe: 'body' })
      .pipe(
        tap((response) => {
          const { access, refresh } = response;
          this.setTokens(access, refresh);
          this.me = this.decodeAccessToken(access);
          this.meSubject.next(this.me);
          this.stateSubject.next('signedIn');
        }),
        catchError((err) => {
          if (err.status === 400 && err.error?.fields) {
            throw new ValidateError(err.error.fields, err.message);
          }
          if (err.status === 405 && err.error?.details) {
            throw new AuthError(err.error.details, err.message);
          }

          throw new Error(err);
        })
      );
  }

  public goAuth(backUrl?: string) {
    this.logOut();
  }

  public logOut() {
    this.resetContext();
  }

  protected setTokens(accessToken: string, refreshToken: string) {
    logger.log('AuthService.setTokens');
    const account = this.decodeAccessToken(accessToken);
    if (account) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    }
  }

  protected resetContext() {
    this.lastAccessToken = '';
    this.accessToken = '';
    this.refreshToken = '';
    this.me = null;
    this.meSubject.next(this.me);
    this.stateSubject.next('signedOut');
    this.router.navigate(['/']);
  }

  public decodeAccessToken(token: string): Account {
    try {
      const token_decoded = jwtDecode<any>(token);
      return new Account(token_decoded.subject);
    } catch (e) {
      console.error('AuthService:parseAccountFromAccessToken', e);
    }
  }

  public recreateToken(force = false) {
    logger.log('AuthService.recreateToken', {
      authProcessing: this.authProcessing,
      force,
    });
    const refreshToken = this.refreshToken;
    if (!refreshToken) {
      this.resetContext();
      return;
    }

    if (this.authProcessing && !force) {
      return;
    }

    if (this.recreateToken$$) {
      this.recreateToken$$.unsubscribe();
    }

    const url = `${environment.account_url}/auth/recreate`;
    this.authProcessing = true;
    this.recreateToken$$ = this.http
      .post<AuthTokensResponse & AuthErrorResponse>(
        url,
        { refresh: refreshToken },
        { observe: 'body' }
      )
      .pipe(
        tap((response) => {
          const { access, refresh, error } = response;
          if (error) {
            // сервер не принял refresh токен или иная причина
            logger.error('AuthService:recreateToken failed', error);
            // Возможно, дисконект. Обновим в другой раз
            // ??? this.resetContext();
          } else {
            this.authProcessing = false;
            if (access && refresh) {
              this.setTokens(access, refresh);
            } else {
              this.resetContext();
            }
          }
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  public refreshAccount(id: string) {
    this.getAccountById(id)
      .pipe(
        tap((account) => {
          this.me = account;
          this.meSubject.next(this.me);
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  public get locale(): LocaleEnum {
    return (localStorage.getItem('locale') as any) || LocaleEnum.en;
  }

  public set locale(locale: LocaleEnum) {
    localStorage.setItem('locale', locale);
  }

  public get audience(): AudienceType {
    return (localStorage.getItem('audience') as any) || AudienceType.web;
  }

  public set audience(audience: AudienceType) {
    localStorage.setItem('audience', audience);
  }

  private getAccountById(account_id: string): Observable<Account> {
    const url = `${environment.account_url}/account/${account_id}`;
    const params = new HttpParams().set('details', ['full'].join(','));
    return this.http.get<Account>(url, { params, observe: 'body' }).pipe(
      map((response) => new Account(response)),
      catchError(() => of(null))
    );
  }

  // public resetPassword(values: IResetPasswordRequest): Observable<IAccountChangeResult> {
  //   const url = `${environment.account_url}/password/reset`;
  //   return this.http
  //     .put<IAccountChangeResult>(url, values, { observe: 'response' })
  //     .pipe(
  //       map(response => response.body as IAccountChangeResult),
  //     );
  // }

  // public checkResetPassword(values: Partial<IConfirmResetPasswordRequest>): Observable<IAccountChangeResult> {
  //   const url = `${environment.auth_url}/password/check`;
  //   return this.http
  //     .post<IAccountChangeResult>(url, values, { observe: 'response' })
  //     .pipe(
  //       map(response => response.body as IAccountChangeResult),
  //     );
  // }

  // public confirmResetPassword(values: IConfirmResetPasswordRequest): Observable<IAccountChangeResult> {
  //   const url = `${environment.auth_url}/password/confirm`;
  //   return this.http
  //     .put<IAccountChangeResult>(url, values, { observe: 'response' })
  //     .pipe(
  //       map(response => response.body as IAccountChangeResult),
  //     );
  // }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }

  private handleLoginError(error: HttpErrorResponse) {
    return throwError({
      error: error ? error : 'Unexpected error, please try later',
    });
  }
}
