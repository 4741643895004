<div class="action">
  <ng-template [ngIfElse]="guestRef" [ngIf]="me">
    <div class="user-menu">
      <div class="avatar-block">
        <ui-cover [cover]="cover"></ui-cover>
      </div>
      <div class="menu-block">
        <div class="menu">
          <p (click)="checkProfile()" class="menu-item">Профиль</p>
          <p (click)="navigateTo('settings')" class="menu-item">Настройки</p>
          <p (click)="signOut()" class="menu-item sign-out">Выйти</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #guestRef>
    <ui-button (click)="openSignInModal()"
               [class]="'gray outline'"
               [label]="'Войти'">
    </ui-button>
  </ng-template>
</div>

<app-modal (closed)="isModalOpen=false"
           *ngIf="!device.isMobile() && isModalOpen"
           [canClose]="true"
           [static]="true"
           [wide]="true"
           class="auth-wrap">
  <app-modal-body>
    <app-auth-modal (checkStep)="checkStep()" (onClose)="onClose($event)" [account]="me"
                    [notFilled]="notFilled"></app-auth-modal>
  </app-modal-body>
</app-modal>

