import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from '@app/components/icon/icon.component';
import {
  MobileModalComponent,
  MobileModalTitleComponent,
  MobileModalBodyComponent,
  MobileModalAdComponent,
} from './mobile-modal.component';

@NgModule({
  declarations: [
    MobileModalComponent,
    MobileModalTitleComponent,
    MobileModalBodyComponent,
    MobileModalAdComponent,
  ],
  imports: [CommonModule, IconComponent],
  exports: [
    MobileModalComponent,
    MobileModalTitleComponent,
    MobileModalBodyComponent,
    MobileModalAdComponent,
  ],
  providers: [],
})
export class MobileModalModule {}
