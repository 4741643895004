import { Injectable } from '@angular/core';
import { AccountService, AuthService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  filter,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import * as ProfileAction from '../actions/profile.actions';
import { getMe, getMyProfile } from '../reducers/profile.reducer';

@Injectable()
export class ProfileEffects {
  public loadMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMe),
      withLatestFrom(this.store.select(getMe)),
      switchMap(([, user]) => {
        if (user) {
          return [];
        }
        return this.authService.me$.pipe(
          map((me) => ProfileAction.loadMeSuccess({ me }))
        );
      })
    )
  );

  public loadMyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMeSuccess),
      filter(({ me }) => !!me),
      withLatestFrom(this.store.select(getMyProfile)),
      switchMap(([{ me }, myProfile]) => {
        if (myProfile?.purpose) {
          return [];
        }
        return this.accountService
          .getAccountById(me.id, ['full'])
          .pipe(
            concatMap((profile) => [
              ProfileAction.loadMyProfileSuccess({ profile }),
            ])
          );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private readonly store: Store,
    private authService: AuthService,
    private accountService: AccountService
  ) {}
}
