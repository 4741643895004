import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  forwardRef,
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormControl,
} from '@angular/forms';
import { IconComponent } from '@app/components/icon/icon.component';
import { SpinnersModule } from '@app/components/shared/spinners/spinners.module';
import { FilesService, IFileType, IImageThumbnail } from '@app/services';
import { DropzoneConfig } from '@app/services/dropzone-config';
import { ChangableComponent } from '@models/changable.component';
import {
  DropzoneConfigInterface,
  DropzoneModule,
} from 'lingo2-ngx-dropzone-wrapper';
import { takeUntil } from 'rxjs/operators';
import { ImageFile } from 'viksi-models';
import { FileTargetEnum } from 'viksi-models/dist';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [CommonModule, DropzoneModule, SpinnersModule, IconComponent],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true,
    },
  ],
})
export class FileUploaderComponent
  extends ChangableComponent
  implements OnInit, ControlValueAccessor
{
  @Input() last: boolean;
  @Input() target: FileTargetEnum = FileTargetEnum.public;
  @Output() changed = new EventEmitter<IFileType>();
  public dropzoneConfig: DropzoneConfigInterface = {
    paramName: 'file',
    uploadMultiple: false,
    clickable: true,
    autoReset: 1,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    dictDefaultMessage: '',
    url: '/',
  };
  public cover_file: IFileType;
  public cover_uploading = false;
  public formControl = new UntypedFormControl();
  public value: IFileType;
  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  constructor(
    protected filesService: FilesService,
    protected dzConfig: DropzoneConfig,
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.configureDropzone();
  }

  writeValue(value: IFileType): void {
    this.value = value;
    this.formControl.setValue(value);
    this.cover_file = this.value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  onChange(value: IFileType): void {
    this.value = value;
    this.propagateChange(value);
    this.changed.emit(value);
    this.detectChanges();
  }

  public onUploadInit(args: any): void {}

  public onUploadStart(args: any): void {
    this.cover_uploading = true;
  }

  public onUploadError(args: any): void {
    this.cover_uploading = false;
  }

  public onUploadSuccess(args: any): void {
    this.cover_uploading = false;
    const [, cover] = args;
    this.cover_file = cover;
    this.onChange(cover);
  }

  public get cover(): ImageFile {
    if (!this.cover_file) {
      return null;
    }
    return this.cover_file.images.md;
  }

  public onRemove() {
    this.filesService
      .removeFile(this.cover_file.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cover_file = null;
        this.onChange(null);
        this.detectChanges();
      });
  }

  protected configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      url: this.dzConfig.url + '/file/upload-process?target=' + this.target, // особый URL для загрузки
    });
  }
}
