<button #button
        class="button"
        [ngClass]="class"
        [class.disabled]="disabled"
        [class.mobile]="isMobile"
        [disabled]="disabled || class === 'status'"
        [ngSwitch]="state"
        (click)="onClick($event)">

  <div *ngSwitchCase="'default'"
       class="label-wrap display"
       [class.after]="iconPosition === 'after'">
    <ng-template [ngIf]="iconPosition">
      <div class="side-icon-wrap">
        <ng-content></ng-content>
      </div>
    </ng-template>
    <span class="label">{{ label }}</span>
  </div>

  <div class="label-wrap hidden" [class.after]="iconPosition === 'after'">
    <ng-template [ngIf]="iconPosition">
      <div class="side-icon-wrap"></div>
    </ng-template>
    <span class="label">{{ label }}</span>
  </div>

  <ng-template [ngSwitchCase]="'in-progress'">
    <ng-container *ngTemplateOutlet="progress"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'done'">
    <ng-container *ngTemplateOutlet="done"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="'fail'">
    <ng-container *ngTemplateOutlet="fail"></ng-container>
  </ng-template>

  <span [class.ripple]="ripple.enabled"
        [style.top.px]="ripple.top"
        [style.left.px]="ripple.left"
        [style.width.px]="ripple.width"
        [style.height.px]="ripple.width">
  </span>
</button>

<ng-template #progress>
  <div class="in-progress">
    <div class="spinner-item one"></div>
    <div class="spinner-item two"></div>
    <div class="spinner-item tree"></div>
  </div>
</ng-template>

<ng-template #done>
  <div class="done">
    <svg width="24" height="24" viewBox="0 0 24 24">
      <use [attr.xlink:href]="svgsetIcon('button-done')"></use>
    </svg>
  </div>
</ng-template>

<ng-template #fail>
  <div class="done">
    <svg width="24" height="24" viewBox="0 0 24 24">
      <use [attr.xlink:href]="svgsetIcon('button-fail')"></use>
    </svg>
  </div>
</ng-template>

