import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject } from 'rxjs';

interface ModalState {
  id: number;
  // no_show: boolean;
  visible: boolean;
}

@Injectable()
export class ModalStackService extends DestroyableComponent {
  private updated = this.register(new BehaviorSubject<boolean>(false));
  public updated$ = this.updated.asObservable();
  private modalStack: ModalState[] = [];

  private updateState() {
    const len = this.modalStack.length;
    if (len === 1) {
      // this.modalStack[0].no_show = false;
      this.modalStack[0].visible = true;
      return;
    }

    const lastModalIndex = this.modalStack.length - 1;
    this.modalStack.forEach((el, index) =>
      // return id === lastModalIndex ? el.no_show = false : el.no_show = true;
      index === lastModalIndex ? (el.visible = true) : (el.visible = false)
    );
  }

  private get lastId() {
    const lastModalIndex = this.modalStack.length - 1;
    return lastModalIndex >= 0 ? this.modalStack[lastModalIndex].id : 0;
  }

  public pushStack(): number {
    const modal: ModalState = {
      id: this.lastId + 1,
      // no_show: false,
      visible: true,
    };
    this.modalStack.push(modal);
    this.updateState();
    // this.setTimeout(() => {
    this.updated.next(true);
    // }, 10);
    return modal.id;
  }

  public popStack() {
    this.modalStack.pop();
    this.updateState();
    this.updated.next(true);
  }

  public isVisible(id: number): boolean {
    // return this.modalStack.find(el => el.id === id).no_show;
    const element = this.modalStack.find((el) => el.id === id);
    return element ? element.visible : false;
  }

  public get visibleCount(): number {
    return this.modalStack.filter((el) => el.visible).length;
  }
}
