import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountSubscription, Cloudpayments } from 'viksi-models';
import {
  AccountSubscribeRequest,
  BillingProviderEnum,
  SubscriptionPack as BillingSubscriptionPack,
} from 'viksi-models';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private modalVisible = false;
  private modalVisibleSubject = new BehaviorSubject<boolean>(this.modalVisible);
  public modalVisible$ = this.modalVisibleSubject.asObservable();

  constructor(private http: HttpClient) {}

  /** Список пакетов тарифных планов */
  public subscriptionsReference(): Observable<BillingSubscriptionPack[]> {
    const url = `${environment.account_url}/billing/reference/subscriptions`;
    return this.http
      .get<BillingSubscriptionPack[]>(url, { observe: 'body' })
      .pipe(
        map((response) => response.map((v) => new BillingSubscriptionPack(v)))
      );
  }

  public findAccountSubscriptions(): Observable<AccountSubscription[]> {
    const url = `${environment.account_url}/billing/subscriptions`;
    return this.http
      .get<AccountSubscription[]>(url, { observe: 'body' })
      .pipe(map((response) => response.map((v) => new AccountSubscription(v))));
  }

  public findAccountSubscription(
    id: string,
    locale: string
  ): Observable<AccountSubscription> {
    const url = `${environment.account_url}/billing/subscription/${id}`;
    const params = new HttpParams().set('locale', 'ru');
    return this.http
      .get<AccountSubscription>(url, { params, observe: 'body' })
      .pipe(map((body) => new AccountSubscription(body)));
  }

  /** Создание подписки */
  public createSubscription(
    subscription_id: number,
    provider: BillingProviderEnum
  ): Observable<AccountSubscription> {
    const url = `${environment.account_url}/billing/subscribe`;
    const request: AccountSubscribeRequest = {
      subscription_id,
      provider,
      options: {},
    };
    return this.http
      .post<AccountSubscription>(url, request, { observe: 'body' })
      .pipe(map((body) => new AccountSubscription(body)));
  }

  /** Отмена подписки */
  public cancelSubscription(
    account_subscription_id: string
  ): Observable<AccountSubscription> {
    const url = `${environment.account_url}/billing/subscription/${account_subscription_id}/unsubscribe`;
    return this.http
      .post<AccountSubscription>(url, {}, { observe: 'body' })
      .pipe(map((body) => new AccountSubscription(body)));
  }

  /** Получение параметров для подписки через CloudPayments */
  public cloudpaymentsRecurringChargeOptions(
    account_subscription_id: string
  ): Observable<Cloudpayments.RecurringChargeOptions> {
    const url = `${environment.account_url}/billing/subscription/${account_subscription_id}/initialize/cloudpayments`;
    return this.http.get<Cloudpayments.RecurringChargeOptions>(url, {
      observe: 'body',
    });
  }

  public showPlansModal() {
    this.modalVisible = true;
    this.modalVisibleSubject.next(this.modalVisible);
  }

  public get isShowPlansModal(): boolean {
    return this.modalVisible;
  }

  public hidePlansModal() {
    this.modalVisible = false;
    this.modalVisibleSubject.next(this.modalVisible);
  }
}
