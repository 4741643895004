import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService, AuthService, IconsService } from '@app/services';
import { AbstractComponent } from '@models/abstract-component';
import { takeUntil } from 'rxjs/operators';
import { Account } from 'viksi-models';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent extends AbstractComponent implements OnInit {
  public me: Account;
  public me_id: string;
  public svgsetIcon = IconsService.svgsetIconUrl;
  public is_footer: boolean;

  constructor(
    protected inject: Injector,
    protected router: Router,
    private authService: AuthService,
    private accountService: AccountService
  ) {
    super(inject);
    router.events.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.is_footer = val.url === '/';
      }
    });
  }

  ngOnInit(): void {
    this.authService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => {
      this.me_id = me?.id;
      this.updateMe();
      this.detectChanges();
    });
  }

  protected updateMe() {
    if (!this.me_id) {
      this.me = null;
      return;
    }

    this.accountService
      .getAccountById(this.me_id, ['full'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((account) => {
        this.me = account;
        this.detectChanges();
      });
  }

  public get year(): number {
    return new Date().getFullYear();
  }
}
