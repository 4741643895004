import { animate, animation, style } from '@angular/animations';

export const moveFadeIn = animation(
  [
    style({ opacity: '0', transform: 'translateY({{slide}})' }),
    animate('{{time}}', style({ opacity: '1', transform: '*' })),
  ],
  { params: { time: '200ms', slide: '5px' } }
);

export const moveFadeOut = animation(
  [
    style({ opacity: '*' }),
    animate(
      '{{time}}',
      style({ opacity: '0', transform: 'translateY({{slide}})' })
    ),
  ],
  { params: { time: '200ms', slide: '5px' } }
);

export const fadeIn = animation(
  [style({ opacity: '0' }), animate('{{time}}', style({ opacity: '1' }))],
  {
    params: { time: '200ms', slide: '5px' },
  }
);

export const fadeOut = animation(
  [style({ opacity: '*' }), animate('{{time}}', style({ opacity: '0' }))],
  {
    params: { time: '200ms' },
  }
);

export const slideIn = animation(
  [
    style({ transform: 'translateX({{slide}})' }),
    animate('{{time}}', style({ transform: 'translateY(0)' })),
  ],
  { params: { time: '200ms', slide: '100vw' } }
);

export const slideOut = animation(
  [
    style({ transform: 'translateX(0)' }),
    animate('{{time}}', style({ transform: 'translateX({{slide}})' })),
  ],
  { params: { time: '200ms', slide: '100vw' } }
);

export const slideDown = animation(
  [
    style({ height: '0', opacity: '0' }),
    animate('{{time}}', style({ height: '*', opacity: '1' })),
  ],
  { params: { time: '200ms' } }
);

export const slideUp = animation(
  [
    style({ height: '*', opacity: '1' }),
    animate('{{time}}', style({ height: '0', opacity: '0' })),
  ],
  { params: { time: '200ms' } }
);
