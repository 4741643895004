<div class="content" *ngIf="accountSubscription">
  <div class="card" [class.active]="canSubscribe" (click)="subscribe()">
    <div class="row-image-title d-flex flex-row align-items-center justify-content-start">
      <img class="image" *ngIf="accountSubscription.subscription.image_url?.length > 10" [src]="accountSubscription.subscription.image_url">
      <div>
        <div class="title html" [innerHTML]="accountSubscription.subscription.description.pack_title_html | sanitizeHtml"></div>
        <div class="period html" [innerHTML]="accountSubscription.subscription.description.period_html | sanitizeHtml"></div>
      </div>
    </div>

    <div class="row-icon-price d-flex flex-row align-items-center">
      <div class="col-ico">
        <ng-container *ngFor="let url of accountSubscription.subscription.icon_url">
          <img class="ico" *ngIf="url?.length > 10" [src]="url">
        </ng-container>
      </div>
      <div class="col-subtitle">
        <div class="html" [innerHTML]="accountSubscription.subscription.description.title_html | sanitizeHtml"></div>
        <ng-container *ngFor="let text of accountSubscription.subscription.description.subtitle_html">
          <div class="html" [innerHTML]="text | sanitizeHtml"></div>
        </ng-container>
      </div>
      <div class="col-price">
        <span class="html" [innerHTML]="accountSubscription.subscription.description.price_html | sanitizeHtml"></span>
      </div>
    </div>

    <!--
    <div class="actions">
      <div class="btn btn-activate" (click)="subscribe()">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1398_14971)">
            <path d="M21.0864 5.74895C20.0277 5.74895 19.1694 6.60722 19.1694 7.66591C19.1698 7.86688 19.2022 8.06649 19.2653 8.2573L17.7106 9.24551C16.687 9.89769 15.3375 9.68154 14.5687 8.74231L13.0448 6.87809C13.2873 6.5516 13.4184 6.15573 13.4186 5.74899C13.4186 4.6903 12.5603 3.83203 11.5016 3.83203C10.4429 3.83203 9.58463 4.6903 9.58463 5.74899C9.58481 6.15573 9.71591 6.5516 9.95844 6.87809L8.43446 8.74231C7.66587 9.681 6.31694 9.8971 5.29356 9.24551L3.73805 8.2573C3.80117 8.06649 3.83352 7.86688 3.83388 7.66591C3.83406 6.60722 2.97597 5.74881 1.91723 5.74863C0.858583 5.74845 0.000179746 6.60659 3.18914e-08 7.66528C-0.000179682 8.6591 0.759201 9.48825 1.7492 9.57519L2.88212 16.3708C2.92053 16.6018 3.12037 16.7713 3.35463 16.7714H19.6487C19.883 16.7713 20.0828 16.6018 20.1212 16.3708L21.2541 9.57519C22.3086 9.48062 23.0868 8.54911 22.9922 7.49464C22.9037 6.50761 22.0774 5.75079 21.0864 5.74895Z" fill="#FFC107"/>
            <path d="M11.5016 18.6888C16.0011 18.6888 19.6486 17.616 19.6486 16.2927C19.6486 14.9693 16.0011 13.8965 11.5016 13.8965C7.00216 13.8965 3.35461 14.9693 3.35461 16.2927C3.35461 17.616 7.00216 18.6888 11.5016 18.6888Z" fill="#FFA000"/>
            <path d="M11.5018 19.1678C7.2145 19.1678 2.87549 18.1806 2.87549 16.2924C2.87549 14.4042 7.2145 13.417 11.5018 13.417C15.789 13.417 20.128 14.4042 20.128 16.2924C20.128 18.1806 15.789 19.1678 11.5018 19.1678ZM11.5018 14.3755C6.46783 14.3755 3.83395 15.6214 3.83395 16.2924C3.83395 16.9634 6.46783 18.2094 11.5018 18.2094C16.5357 18.2094 19.1696 16.9634 19.1696 16.2924C19.1696 15.6214 16.5357 14.3755 11.5018 14.3755Z" fill="#FFD54F"/>
          </g>
          <defs>
            <clipPath id="clip0_1398_14971">
              <rect width="23" height="23" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span>Активировать</span>
      </div>
    </div>
    -->
  </div>

  <div class="spacer"></div>

  <div class="payment-form">
    <div #form></div>
  </div>
</div>

<swal #swalRef [swalOptions]="swalOptions"></swal>

<!--
<pre>{{ accountSubscription.subscription | json }}</pre>
-->
