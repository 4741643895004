import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AbstractComponent } from '../../models/abstract-component';
import { IconsService } from '../../services/icons.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  public h1_active = false;
  public h2_active = false;
  public btn_active = false;
  public useCloudPayments = false;

  public android_app_url = environment.android_app_url;
  public ios_app_url = environment.ios_app_url;
  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected inject: Injector
  ) {
    super(inject);
  }

  ngOnInit() {
    this.setTimeout(() => {
      this.h1_active = true;
      this.detectChanges();
    }, 200);
    this.setTimeout(() => {
      this.h2_active = true;
      this.detectChanges();
    }, 400);
    this.setTimeout(() => {
      this.btn_active = true;
      this.detectChanges();
    }, 600);
  }

  ngOnDestroy() {}

  public say(phrase: string): string {
    switch (phrase) {
      case 'landing-home1.title0':
        return 'Тиндер по-русски';

      case 'landing-home1.title1':
        return 'Viksi - приложение для знакомств';

      case 'landing-home1.title2':
        return 'Дай волю своим чувствам. Найди друзей. Найди любовь. Найди счастье.';

      case 'landing-home1.text1':
        return 'Ищу любовь';

      case 'landing-home1.text2':
        return 'Секс без обязательств';

      case 'landing-home1.text3':
        return 'Ищу Gold';
      // return 'Ищу спонсора';

      case 'landing-home1.text4':
        return 'Gold';
      // return 'Стану спонсором';

      case 'landing-home1.text5':
        return 'Внутри приложения возможны покупки';
    }

    return '?' + phrase;
  }
}
