import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IconComponent } from '@app/components/icon/icon.component';
import { AccountService, ConfigService } from '@app/services';
import { Subject } from 'rxjs';
import {
  Account,
  AccountAccessRule,
  AccountAccessRuleEnum,
  Purpose,
} from 'viksi-models';

@Component({
  selector: 'app-purpose-form',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './purpose-form.component.html',
  styleUrls: ['./purpose-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurposeFormComponent implements OnInit {
  @Input() account: Account;
  @Input() purpose: Purpose;
  @Input() purposes: Purpose[];
  @Output() changed = new EventEmitter<Purpose>();
  private ngUnsubscribe = new Subject();

  constructor(
    protected accountService: AccountService,
    protected configService: ConfigService,
    protected readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  public cssStyles(purpose: Purpose) {
    return {
      color: this.isActivePurpose(purpose)
        ? '#' + purpose.alternate_color
        : '#' + purpose.color,
      borderColor: '#' + purpose.color,
      backgroundColor: this.isActivePurpose(purpose)
        ? '#' + purpose.color
        : '#' + purpose.alternate_color,
    };
  }

  public get can_set_purpose_be_sponsor(): AccountAccessRule {
    return (this.account.access?.rules || []).find(
      (r) => r.code === AccountAccessRuleEnum.can_set_purpose_be_sponsor
    );
  }

  public isActivePurpose(purpose: Purpose): boolean {
    return purpose?.id === this.purpose?.id;
  }

  public setPurpose(purpose: Purpose) {
    if (this.isActivePurpose(purpose)) {
      return;
    }

    // if (
    //   ['be_sponsor', 'find_sponsor'].includes(purpose.code) &&
    //   !this.can_set_purpose_be_sponsor?.enabled
    // ) {
    //   this.cdr.detectChanges();
    //   return;
    // }

    this.purpose = purpose;
    this.changed.emit(purpose);
    this.cdr.detectChanges();
  }
}
