import {ChangeDetectorRef, Directive, ElementRef, Injector, OnDestroy, Renderer2, ViewRef} from '@angular/core';
import {BehaviorSubject, interval, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {sampleTime, filter, first, mergeAll, takeUntil, tap} from 'rxjs/operators';
import {addMilliseconds} from 'date-fns';
// import {environment} from '@environments/environment';
// import {ErrorService} from '@app/services/error.service';
// import {createDummyLogger, createLogger, ILogger} from '@app/models/logger';

type TimerLike = number | Subscription;

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {

  // public svgsetMeetIcon: (icon: string) => string = IconsService.svgIconUrl;
  // public svgsetIcon: (icon: string) => String = IconsService.svgIconUrl;

  protected abstractElementRef: ElementRef;
  protected abstractRenderer: Renderer2;
  // protected logger: ILogger = createDummyLogger();
  protected changeDetector: ChangeDetectorRef;
  // protected errorService: ErrorService;

  protected readonly completable: Subject<any>[] = [];
  protected readonly destroyed$ = new Subject<boolean>();

  private readonly detectChanges$ = new BehaviorSubject<boolean>(null);

  protected constructor(
    protected inject: Injector,
  ) {
    // this.logger = createLogger('onclass:' + this.constructor.name);

    this.changeDetector = this.inject.get(ChangeDetectorRef);
    // this.errorService = this.inject.get(ErrorService);

    if (ElementRef && Renderer2) {
      this.abstractRenderer = this.inject.get(Renderer2);
      this.abstractElementRef = this.inject.get(ElementRef);
    }

    of(...[
      this.watchDetectChanges$,
    ])
      .pipe(
        mergeAll(),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.completable.map(s => s.complete());
    this.completable.splice(0);
  }

  // /** Включена ли отдельная функциональная возможность, логический блок или компонента */
  // public isFeatureAvailable(feature: FeaturesType): boolean {
  //   return environment.features[feature];
  // }

  // /** Классы CSS для мобильных устройств */
  // public get screenClasses(): any {
  //   return {
  //     tablet: this.isTablet,
  //     mobile: this.isMobile,
  //     portrait: !this.isNormal && this.isPortrait,
  //     landscape: !this.isNormal && !this.isPortrait,
  //   };
  // }

  /** Слежение за запросами на рендер компонента */
  protected get watchDetectChanges$() {
    return this.detectChanges$
      .pipe(
        filter(changeDetect => !!changeDetect && this.changeDetector && !(this.changeDetector as ViewRef).destroyed),
        sampleTime(32),
        tap(() => this.changeDetector.detectChanges()),
      );
  }

  protected register<T extends Subject<any>>(instance: T): T {
    this.completable.push(instance);
    return instance;
  }

  protected setTimeout(func: () => void, timeout: number): Subscription {
    const dueTime = addMilliseconds(new Date(), timeout);

    return timer(dueTime)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(func);
  }

  protected clearTimeout(timerLike: TimerLike) {
    if (!timerLike) { return; }
    if (typeof timerLike === 'number') {
      clearTimeout(timerLike);
    } else {
      if ('unsubscribe' in timerLike) { // instanceof Subscription
        timerLike.unsubscribe();
      }
    }
  }

  protected setInterval(func: () => void, period: number): Subscription {
    return interval(period)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(func);
  }

  protected clearInterval(intervalLike: TimerLike) {
    if (!intervalLike) { return; }
    if (typeof intervalLike === 'number') {
      clearInterval(intervalLike);
    } else {
      if ('unsubscribe' in intervalLike) { // instanceof Subscription
        intervalLike.unsubscribe();
      }
    }
  }

  protected detectChanges() {
    this.detectChanges$.next(true);
  }
}
