import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Device, DEVICE } from '@le2xx/ngx-toolkit-device';
import { ChangableComponent } from '../../../models/changable.component';
import { IconsService, PlatformService } from '../../../services';

export type OnUiButtonState = 'default' | 'in-progress' | 'done' | 'fail';
export type OnUiButtonIconPosition = 'before' | 'after';
export type OnUiButtonClass =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'third'
  | 'transparent'
  | 'status'
  | 'white'
  | 'grey';

@Component({
  selector: 'ui-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiButtonComponent extends ChangableComponent implements OnInit {
  @Input() label: string;
  @Input() class: OnUiButtonClass = 'primary';
  @Input() disabled: boolean;
  @Input() iconPosition: OnUiButtonIconPosition;
  @Input() public set state(state: OnUiButtonState) {
    this._state = state;
  }
  public get state(): OnUiButtonState {
    return this._state;
  }

  @Output() onclick = new EventEmitter<Event>();

  @ViewChild('button') buttonRef: ElementRef;
  public ripple = {
    enabled: false,
    top: 0,
    left: 0,
    width: 0,
  };
  public clickBlocked = false;
  public svgsetIcon = IconsService.svgsetIconUrl;

  private _state: OnUiButtonState = 'default';
  private _animationTime = 600;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
    @Inject(DEVICE) private device: Device
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}

  public get isMobile(): boolean {
    return this.device.isMobile();
  }

  public onClick(event: MouseEvent) {
    if (this.clickBlocked || this.disabled || this.state === 'in-progress') {
      return;
    }
    this.onclick.emit(event);
    this.clickBlocked = true;
    const btn = this.buttonRef.nativeElement;
    const diameter = btn.clientWidth;
    // logger.log(event.offsetY, diameter, event, this.buttonRef);
    this.ripple = {
      enabled: true,
      top: event.offsetY - diameter,
      left: event.offsetX - diameter,
      width: diameter * 2,
    };

    this.setTimeout(() => {
      this.ripple.enabled = false;
      this.clickBlocked = false;
      this.markForCheck();
    }, this._animationTime);
    this.markForCheck();
  }
}
