// tslint:disable:no-console
import {Injectable, OnDestroy} from '@angular/core';
import {
  BehaviorSubject,
  interval,
  Observable,
  Observer,
  of,
  race,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  share,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs/operators';
import {WebSocketSubject, WebSocketSubjectConfig} from 'rxjs/webSocket';
import {isArray} from 'lodash';
import {environment} from "@env/environment";
import {AuthService} from "@app/services";
import {
  AbstractWsEvent,
  AbstractWsMessage,
  castEvent,
  WsAccessEvent,
  WsAuthEvent,
  WsAuthMessage,
  WsChatMessageEvent,
  WsChatMessageStatusEvent,
  WsChatMessageStatusMessage,
  WsChatThreadEvent,
  WsChatThreadMessage,
  WsChatThreadMessagesEvent,
  WsChatThreadMessagesMessage,
  WsChatThreadsEvent,
  WsChatThreadsMessage,
  WsChatThreadStatusEvent,
  WsChatTypingEvent,
  WsChatTypingMessage,
  WsCreateChatMessageMessage,
  WsEventEnum,
  WsFiltersUpdatedEvent,
  WsGeoEvent,
  WsHotStatusEvent,
  WsLikeEvent,
  WsLikesEvent,
  WsMutualLikeEvent,
  WsOnlineStatusEvent,
  WsPremiumAccessEvent,
  WsPremiumExpiredEvent,
  WsReferencesCombinedUpdatedEvent,
  WsStartWatchMessage,
  WsStatusEvent,
  WsStopWatchAllMessage,
  WsStopWatchMessage,
  WsAccessUpdatedEvent,
  WsVerificationEvent,
  WsVisitEvent,
} from 'viksi-models';
import {WebSocketConfig} from './websockets.models';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService implements OnDestroy {
  public status$: Observable<boolean>;

  private _authorized = new BehaviorSubject<boolean>(false);
  public authorized$ = this._authorized.asObservable();

  private config: WebSocketSubjectConfig<AbstractWsMessage>;

  private connection$: Observer<boolean>;
  private reconnection$: Observable<number>;
  private sendingMessages$: WebSocketSubject<AbstractWsMessage>;
  private receiveMessages$: Subject<AbstractWsEvent> = new Subject<AbstractWsEvent>();

  private status$$: Subscription;
  private receiveMessages$$: Subscription;

  private reconnectInterval: number;
  private reconnectAttempts: number;
  private isConnected: boolean;

  private watches: { [key: string]: number; } = {};
  private networkError = false;
  private heartbeatSubscription: Subscription;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
  ) {
    this.configure();

    // connection status
    this.status$ = new Observable<boolean>((observer) => {
      this.connection$ = observer;
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(share(), distinctUntilChanged());

    this.authService.me$
      .pipe(
        map((me) => me?.id),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        this.init();
        this.connect();
        this.auth();
      });
  }

  private configure() {
    console.log('WebsocketService:configure');
    const wsConfig: WebSocketConfig = {
      reconnectInterval: 5000,
      reconnectAttempts: 1000,
      url: environment.ws_url, // TODO из настроек contextService.ws
    };

    this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
    this.reconnectAttempts = wsConfig.reconnectAttempts || 100; // number of connection attempts

    this.config = {
      url: wsConfig.url,
      closeObserver: {
        next: (event: CloseEvent) => {
          console.debug('WebsocketService disconnected');
          this.sendingMessages$ = null;
          this.connection$.next(false);
        }
      },
      openObserver: {
        next: (event: Event) => {
          console.debug('WebsocketService connected');
          this.connection$.next(true);
          this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
          this.reconnectAttempts = wsConfig.reconnectAttempts || 100; // number of connection attempts
          this.auth();
          this.startHeartbeat();
        }
      }
    };
  }

  private init() {
    console.log('WebsocketService:init');

    if (this.status$$) {
      this.status$$.unsubscribe();
    }

    // run reconnect if not connection
    this.status$$ = this.status$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (isConnected) => {
          this.isConnected = isConnected;
          if (!this.reconnection$ && typeof (isConnected) === 'boolean' && !isConnected) {
            this.reconnect();
          }
        });

    if (this.receiveMessages$$) {
      this.receiveMessages$$.unsubscribe();
    }
    this.receiveMessages$$ = this.receiveMessages$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (msg) => {
          console.log('WebsocketService:received', msg);
        },
        (error: ErrorEvent) => {
          console.error('WebsocketService:received:error', error);
        },
      );

    this.onAuthComplete
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        console.log('onAuthComplete -> ws.authorized = true');
        this._authorized.next(true);
      });
  }

  ngOnDestroy() {
    this.stopWatchingAll();
    this.disconnect();
    this.stopHeartbeat();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /** Авторизация на сокет-сервере */
  private auth() {
    console.debug('WebsocketService:auth');
    console.log('auth() -> ws.authorized = false -> WsAuthMessage');
    this._authorized.next(false);

    const message = WsAuthMessage.createInstance({
      messageId: Date.now().toString(), withAsk: true,
      token: this.authService.accessToken,
    });
    this.send(message);
    this.onAsk(message.messageId)
      .pipe(
        tap(() => {
          console.log('auth() -> WsAuthMessage -> restartWatching');
          this.restartWatching(Object.keys(this.watches));
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  /**
   * connect to WebSocket
   */
  private connect(): void {
    if (this.isConnected) {
      return;
    }
    console.log('WebsocketService:connect');

    if (this.sendingMessages$) {
      this.sendingMessages$.unsubscribe();
      this.sendingMessages$.complete();
    }

    this.sendingMessages$ = new WebSocketSubject(this.config);

    let sendMessages$$: Subscription;
    sendMessages$$ = this.sendingMessages$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (message) => this.receiveMessages$.next(message as any),
        (error) => {
          sendMessages$$?.unsubscribe();

          if (!this.sendingMessages$) {
            // run reconnect if errors
            this.reconnect();
          }
        },
        () => {
          sendMessages$$?.unsubscribe();
        });
  }

  /**
   * disconnect from WebSocket
   */
  private disconnect(): void {
    if (this.connection$) {
      this.connection$.complete();
    }
  }

  /**
   * reconnect if not connecting or errors
   */
  private reconnect(): void {
    this.reconnection$ = interval(this.reconnectInterval)
      .pipe(takeWhile((v, index) => index < this.reconnectAttempts && !this.sendingMessages$));

    const reconnection$$ = this.reconnection$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.connect();
        },
        (err) => {
          console.error('WebsocketService:reconnect()', 'error', err);
        },
        () => {
          reconnection$$.unsubscribe();
          // Subject complete if reconnect attemts ending
          this.reconnection$ = null;

          if (this.sendingMessages$) {
            console.debug('WebsocketService:reconnect()', 'success');
          } else {
            console.error('WebsocketService:reconnect()', 'failed');
            this.receiveMessages$.complete();
            this.connection$.complete();
            if (this.sendingMessages$) {
              this.sendingMessages$.unsubscribe();
              this.sendingMessages$.complete();
              this.sendingMessages$ = null;
            }
          }
        });
  }

  /**
   * on received message
   * рекомендуется написать более строгий метод с указанием возвращаемого типа данных
   * см. onAuthComplete onNoticeRead и другие
   */
  private on<T extends AbstractWsEvent>(eventType: WsEventEnum): Observable<T> {
    return this.receiveMessages$
      .pipe(
        filter((event: T) => (typeof(event) === 'object' && 'event' in event && event.event === eventType)),
        map((event: T) => castEvent(event)),
        takeUntil(this.ngUnsubscribe)
      );
  }

  public onAsk<T extends AbstractWsEvent>(askMessageId: string): Observable<T> {
    return this.receiveMessages$
      .pipe(
        filter((event: T) => (typeof(event) === 'object' && 'event' in event && event.askMessageId === askMessageId)),
        map((event: T) => castEvent(event)),
        takeUntil(this.ngUnsubscribe),
      );
  }

  /** Send message to server */
  private send<M extends AbstractWsMessage>(message: M): void {
    if (message) {
      if (this.isConnected && this.sendingMessages$) {
        // this.websocket$.next(<any>JSON.stringify({event, data}));
        this.sendingMessages$.next(message);
        console.log('WebsocketService:send', { message });
      } else {
        // console.log('WebsocketService:send:error', { event, data }, 'Socket not connected or disconnected');
        // console.log('WebsocketService:send retry', { event, data });
        // this.setTimeout(() => {
        //   this.send(event, data);
        // }, 250 + Math.round(1000 * Math.random()));
      }
    }
  }

  private ping() {
    if (this.isConnected && this.sendingMessages$) {
      this.sendingMessages$.next('ping' as any);
    }
  }

  protected startHeartbeat(): void {
    this.stopHeartbeat();
    this.networkError = false;

    const heartbeat$: Observable<'pong' | 'timeout' | 'error'> = timer(1000, 30000)
      .pipe(
        tap(() => this.ping()),
        concatMap(() => {
          return race(
            of('timeout').pipe(delay(3000)),
            this.onPong().pipe(catchError(() => of ('error'))),
            // this.connection$.pipe(filter(m => m === 'pong'), catchError(() => of('error')))
          );
        })
      );

    this.heartbeatSubscription = heartbeat$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((msg) => {
        if (msg === 'pong') {
          this.networkError = false;
        } else {
          this.networkError = true;
          console.error('websocket connection lost');
          // this.webSocketSubject?.complete();
          // this.webSocketSubject = null;
        }
      });
  }

  protected stopHeartbeat() {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
    }
  }

  private onPong(): Observable<any> {
    return this.receiveMessages$
      .pipe(
        filter((event: any) => event === 'pong'),
      );
  }

  /** ДОбавить в наблюдаемые всех ранее наблюдаемых пользователей */
  protected restartWatching(account_id: string[]) {
    this.send(WsStartWatchMessage.createInstance({
      account_id,
    }));
  }

  /** Добавить в наблюдаемые пользователя (пользователей) */
  public startWatching(account_id: string | string[]) {
    const accountIds = isArray(account_id) ? account_id : [account_id];
    if (this._authorized.value) {
      this.send(WsStartWatchMessage.createInstance({
        account_id: accountIds as string[],
      }));
    }
    // инкрементировать количество наблюдателей
    (accountIds as string[]).map((id) => {
      this.watches[id] = (this.watches[id] || 0) + 1;
    });
  }

  /** Удалить из наблюдаемых пользователя (пользователей) */
  public stopWatching(account_id: string | string[]) {
    const accountIds = isArray(account_id) ? account_id : [account_id];
    // декрементировать количество наблюдателей и удалить, если наблюдателей не осталось
    (accountIds as string[]).map((id) => {
      this.watches[id] = (this.watches[id] || 0) - 1;
      if (this.watches[id] <= 0) {
        delete this.watches[id];
      }
    });
    if (this._authorized.value) {
      this.send(WsStopWatchMessage.createInstance({
        account_id: accountIds as string[],
      }));
    }
  }

  /**
   * Удалить из наблюдаемых всех пользователей
   * например, при logout
   */
  public stopWatchingAll() {
    this.send(WsStopWatchAllMessage.createInstance({}));
    this.watches = {};
  }

  /** Сообщение об авторизации на сокет-сервере */
  public get onAuthComplete() {
    return this.on<WsAuthEvent>(WsEventEnum.WsAuthEvent);
  }

  public findChatThread(message: WsChatThreadMessage) {
    this.send(message);
  }

  public findChatThreads(message: WsChatThreadsMessage) {
    this.send(message);
  }

  public findChatThreadMessages(message: WsChatThreadMessagesMessage) {
    this.send(message);
  }

  public createChatMessage(message: WsCreateChatMessageMessage) {
    this.send(message);
  }

  public changeChatMessageStatus(message: WsChatMessageStatusMessage) {
    this.send(message);
  }

  public typing(message: WsChatTypingMessage) {
    this.send(message);
  }

  public onChatThread() {
    return this.on<WsChatThreadEvent>(WsEventEnum.WsChatThreadEvent);
  }

  public onChatThreads(): Observable<WsChatThreadsEvent> {
    return this.on<WsChatThreadsEvent>(WsEventEnum.WsChatThreadsEvent);
  }

  public onChatThreadStatusEvent(): Observable<WsChatThreadStatusEvent> {
    return this.on<WsChatThreadStatusEvent>(WsEventEnum.WsChatThreadStatusEvent);
  }

  public onChatThreadMessages(): Observable<WsChatThreadMessagesEvent> {
    return this.on<WsChatThreadMessagesEvent>(WsEventEnum.WsChatThreadMessagesEvent);
  }

  public onChatMessage(): Observable<WsChatMessageEvent> {
    return this.on<WsChatMessageEvent>(WsEventEnum.WsChatMessageEvent);
  }

  public onChatMessageStatus(): Observable<WsChatMessageStatusEvent> {
    return this.on<WsChatMessageStatusEvent>(WsEventEnum.WsChatMessageStatusEvent);
  }

  public onTyping(): Observable<WsChatTypingEvent> {
    return this.on<WsChatTypingEvent>(WsEventEnum.WsChatTypingEvent);
  }

  /** Событие - изменение статуса пользователя */
  public onStatusEvent(): Observable<WsStatusEvent> {
    return this.on<WsStatusEvent>(WsEventEnum.WsStatusEvent);
  }

  /** Событие - изменение "горячего" статуса пользователя */
  public onHotStatusEvent(): Observable<WsHotStatusEvent> {
    return this.on<WsHotStatusEvent>(WsEventEnum.WsHotStatusEvent);
  }

  /** Событие - изменение онлайн статуса пользователя */
  public onOnlineStatusEvent(): Observable<WsOnlineStatusEvent> {
    return this.on<WsOnlineStatusEvent>(WsEventEnum.WsOnlineStatusEvent);
  }

  /** Событие - изменение гео-позиции пользователя */
  public onGeoEvent(): Observable<WsGeoEvent> {
    return this.on<WsGeoEvent>(WsEventEnum.WsGeoEvent);
  }

  /** Событие - верификация профиля */
  public onVerificationEvent(): Observable<WsVerificationEvent> {
    return this.on<WsVerificationEvent>(WsEventEnum.WsVerificationEvent);
  }

  /** Событие - премиальный доступ */
  public onPremiumAccessEvent(): Observable<WsPremiumAccessEvent> {
    return this.on<WsPremiumAccessEvent>(WsEventEnum.WsPremiumAccessEvent);
  }

  /** Событие - премиальный доступ завершился */
  public onPremiumExpiredEvent(): Observable<WsPremiumExpiredEvent> {
    return this.on<WsPremiumExpiredEvent>(WsEventEnum.WsPremiumExpiredEvent);
  }

  /** Событие - смена прав доступа */
  public onWsAccessEvent(): Observable<WsAccessEvent> {
    return this.on<WsAccessEvent>(WsEventEnum.WsAccessEvent);
  }

  /** Событие - смена прав доступа визитора к другому пользователю */
  public onWsVisitEvent(): Observable<WsVisitEvent> {
    return this.on<WsVisitEvent>(WsEventEnum.WsVisitEvent);
  }

  /** Событие - изменились тарифные планы, настройки подписок, права доступа */
  public WsAccessUpdatedEvent(): Observable<WsAccessUpdatedEvent> {
    return this.on<WsAccessUpdatedEvent>(WsEventEnum.WsAccessUpdatedEvent);
  }

  /** Событие - кто-то лайкнул пользователя */
  public onLike(): Observable<WsLikeEvent> {
    return this.on<WsLikeEvent>(WsEventEnum.WsLikeEvent);
  }

  /** Событие - кто-то взаимно лайкнул пользователя */
  public onMutualLike(): Observable<WsMutualLikeEvent> {
    return this.on<WsMutualLikeEvent>(WsEventEnum.WsMutualLikeEvent);
  }

  /** Событие - количество лайков */
  public onLikes(): Observable<WsLikesEvent> {
    return this.on<WsLikesEvent>(WsEventEnum.WsLikesEvent);
  }

  /** Событие - изменился справочник ReferencesCombined */
  public onReferencesCombinedUpdated(): Observable<WsReferencesCombinedUpdatedEvent> {
    return this.on<WsReferencesCombinedUpdatedEvent>(WsEventEnum.WsReferencesCombinedUpdatedEvent);
  }

  /** Событие - изменился справочник Filters */
  public onFiltersUpdated(): Observable<WsFiltersUpdatedEvent> {
    return this.on<WsFiltersUpdatedEvent>(WsEventEnum.WsFiltersUpdatedEvent);
  }
}
