<label class="textarea"
       [class.disabled]="isDisabled"
       [class.error]="error">
  <textarea type="text"
            class="input"
            [style.height.px]="textHeight"
            [formControl]="formControl"
            [attr.maxlength]="maxlength"
            [placeholder]="placeholder"
            [attr.disabled]="isDisabled ? true : null"
            (blur)="onBlur()"
            (input)="onChangeText()">
  </textarea>
  <div class="shadow-text-wrap">
    <code #shadowText class="shadow-text">{{ value }}</code>
  </div>
  <span class="counter">{{ value?.length || 0 }} / {{maxlength}}</span>
</label>
