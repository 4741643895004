import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MeComponent } from '@app/components/me/me.component';
import { SubscriptionComponent } from '@app/components/subscription/subscription.component';
import { ROOT_REDUCERS, metaReducers } from '@app/store';
import { ProfileEffects } from '@app/store/effects/profile.effects';
import { DeviceModule } from '@le2xx/ngx-toolkit-device';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './interceptors';
import { LayoutComponent } from './layout/layout.component';
import {
  MainComponent,
  PrivacyPageComponent,
  StatusPageComponent,
  SubscriptionPageComponent,
  SupportPageComponent,
  TermsOfUsePageComponent,
} from './pages';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    DeviceModule.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
    }),
    EffectsModule.forRoot([ProfileEffects]),
    PipesModule,
    BrowserAnimationsModule,
    MeComponent,
    SubscriptionComponent,
  ],
  declarations: [
    AppComponent,

    /* pages */
    MainComponent,
    PrivacyPageComponent,
    StatusPageComponent,
    SubscriptionPageComponent,
    SupportPageComponent,
    TermsOfUsePageComponent,

    /* components */
    LayoutComponent,
  ],
  providers: [...httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
