import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

// компоненты
import { Spinner1 } from './spinner1';
import { Spinner2 } from './spinner2';

// сервисы

@NgModule({
  declarations: [Spinner1, Spinner2],
  imports: [CommonModule],
  exports: [Spinner1, Spinner2],
})
export class SpinnersModule {
  static forServer(): ModuleWithProviders<SpinnersModule> {
    return {
      ngModule: SpinnersModule,
    };
  }
}
