import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AuthService } from '../services';

/**
 * При ошибке 401 отправляет пользователя на авторизацию
 */
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private location: Location,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((error) => {
        if (error) {
          switch (error.status) {
            case 401: {
              this.Error401Handler();
              break;
            }
            default: {
              break;
            }
          }
        }
        // TODO: If Notification (toast) service is present we can show current error notification
        return throwError(error);
      }));
  }

  Error401Handler() {
    this.authService.goAuth(this.location.path());
  }
}
