import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner1',
  template:
    '<div class="spinner1"><div class="dot1"></div><div class="dot2"></div></div>',
  styleUrls: ['./spinner1.scss'],
})
export class Spinner1 {
  @Input()
  public active = false;
}
