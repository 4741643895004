import { Injectable } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@env/environment';
import { DropzoneConfigInterface } from 'lingo2-ngx-dropzone-wrapper';

@Injectable({
  providedIn: 'root',
})
export class DropzoneConfig {
  public url: string;
  public maxFiles = 1;
  public maxFilesize = 5 * 1024 * 1024; // 5Mb

  constructor(private auth: AuthService) {
    this.url = environment.files_url;
  }

  private get headers(): any {
    const tokenExists =
      this.auth && this.auth.accessToken && this.auth.accessToken.length;
    if (tokenExists) {
      return {
        Authorization: `Bearer ${this.auth.accessToken}`,
      };
    }
    return null;
  }

  public combine(
    config1: DropzoneConfigInterface,
    config2: DropzoneConfigInterface
  ): DropzoneConfigInterface {
    const { url, headers, maxFiles, maxFilesize } = this;
    return {
      url,
      headers,
      maxFiles,
      maxFilesize,
      ...config1,
      ...config2,
    };
  }
}
