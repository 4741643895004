<div class="container">

  <p>Effective on 2022-02-02</p>

  <p>This privacy
    statement describes how {{ organizationName }} collects and uses the personal information you provide. It also describes the
    choices available to you regarding our use of your personal information and how you can access and update this
    information.</p>

  <h1>Location Services</h1>

  <p class="b">Some services on
    this site make use of location-based data. If you allow these services, we will collect information about the Wi-fi
    routers closest to you and the cell IDs of the towers closest to you.</p>

  <p class="b">We use this information to
    provide the requested location-based service, not to identify you. You will be asked for your permission each time a
    location-based service is requested.</p>

  <ul>
    <li>Provide you content based upon your location.</li>
    <li>Check you in to
      a store or other location to enable you to earn loyalty rewards or let others know where you are.</li>
    <li>Provide you special local offers.</li>
  </ul>

  <h1>Collection and Use</h1>

  <p class="b">We collect the following personal information from you:</p>

  <ul>
    <li>Contact Information such as name, email address, mailing address, phone number if provided.</li>
    <li>Unique Identifiers such as user name, account number, password.</li>
    <li>Preferences
      Information you provide such as product or content interests, or communication or marketing preferences.</li>
    <li>Usage
      activity about how you interact with us such as purchase history, what content you viewed, and which areas of our
      site you visited.</li>
    <li>Device Information.</li>
  </ul>

  <p class="b">We also collect the following information from you:</p>

  <ul>
    <li>Demographic information such as household income, age, education, gender, interests, and zip code.</li>
    <li><p>Information about your mobile device such as your mobile device id (similar to a website IP address), device type,
      and mobile service carrier.</p>
      <p>As is true of most Web sites, we
        automatically gather information about your computer such as your IP address, browser type, referring/exit
        pages, and operating system.</p>
    </li>
  </ul>

  <h2>We use this information to:</h2>

  <ul>
    <li>Fulfill your order.</li>
    <li>Assess your needs to determine suitable products or services.</li>
    <li>Respond to customer service requests.</li>
    <li>Send you push notifications when you are using our
      app. If you do not want these notifications, you can manage your preference either through your device or app
      settings depending on your device type. You can also contact us at
      <a href="mailto:{{ organizationEmail }}" target="_blank">{{ organizationEmail }}</a>
    </li>
    <li>Send you marketing communications.</li>
    <li>Improve our App and marketing efforts.</li>
    <li>Conduct research and analysis.</li>
    <li>Respond to your questions and concerns.</li>
    <li>Display content based upon your interests.</li>
  </ul>

  <p>We classify
    user information including but not limited to, call logs, message logs, email address, phone number, name,
    social media account information, and contacts as private and confidential information.</p>
  <h1>Choice/Opt-Out</h1>
  <p>
    You may opt-out of receiving our newsletter or
    marketing emails from us by following the unsubscribe instructions included in each newsletter or marketing
    email or you can contact us at
    <a href="mailto:{{ organizationEmail }}" target="_blank">{{ organizationEmail }}</a>
  </p>

  <h1>Social Networks</h1>

  <p>We enable you
    to create a profile, and share information such as messages, photos, and videos with others within your network.
    We cannot control the actions of others with whom you may choose to share your pages and information. Also we
    cannot guarantee that the content you post on our site will not be viewed by others outside your network.</p>

  <h1>Information Obtained from Third Parties</h1>

  <p>If you provide
    us personal information about others, or if others give us your information, we will only use that information
    for the specific reason for which it was provided to us.</p>

  <h1>Sharing Your Information</h1>

  <p>We will share your information with third parties only in the ways that are described in this privacy statement.</p>

  <p>We may provide your personal
    information to companies that provide services to help us with our business activities such as shipping your
    order or offering customer service. These third parties are authorized to use your personal information only as
    necessary to provide these services to us.</p>

  <h1>We may disclose your personal information</h1>

  <ul>
    <li>as required by law, such as to comply with a subpoena, or similar legal process.</li>
    <li>when we believe
      in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others,
      investigate fraud, or respond to a government request.</li>
    <li>if {{ organizationName }} is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via
      email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information,
      as well as any choices you may have regarding your personal information.</li>
    <li>to any other third party with your prior consent to do so.</li>
  </ul>

  <p>We make your personal information available to other
    companies so that they can market their products or services to you. If you do not want us to make your personal
    information available to these companies, contact us at
    <a href="mailto:{{ organizationEmail }}" class="a" target="_blank">{{ organizationEmail }}</a></p>

  <h1>Tracking and Ads</h1>

  <p>We partner with a third party ad
    network to either display advertising on our App or to manage our advertising on other sites. Our ad network partner
    uses technologies to collect non-personally identifiable information about your activities on this and other Apps to
    provide you targeted advertising based upon your interests.</p>

  <h1>Security</h1>

  <p>The security of
    your personal information is important to us. When you provide sensitive information (such as a credit card number)
    to us, we encrypt the transmission of that information using secure socket layer technology (SSL).</p>

  <p>We follow generally accepted
    industry standards to protect the personal information submitted to us, both during transmission and once we receive
    it. No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its
    absolute security.</p>

  <p>If you have any questions about security on our Web site, you can contact us at
    <a href="mailto:{{ organizationEmail }}" target="_blank">{{ organizationEmail }}</a>
  </p>

  <p>We will
    retain your information for as long as your account is active or as needed to provide you services. If you wish to
    cancel your account or request that we no longer use your information to provide you services contact us at
    <a href="mailto:{{ organizationEmail }}" class="a" target="_blank">{{ organizationEmail }}</a>.
    We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and
    enforce our agreements.</p>

  <h1>Other Information</h1>

  <h2>Correcting and Updating Your Personal Information</h2>

  <p>To review and update your personal information to ensure
    it is accurate, contact us at
    <a href="mailto:{{ organizationEmail }}" class="s1" target="_blank">{{ organizationEmail }}</a>.</p>

  <h2>Notification of Privacy Statement Changes</h2>

  <p>We may update this
    privacy statement to reflect changes to our information practices. If we make any material changes we will notify
    you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to
    the change becoming effective. We encourage you to periodically review this page for the latest information
    on our privacy practices. Contact</p>

  <p>You can contact us about this privacy statement by writing or email us at the address below:</p>

  <p>{{ organizationName }}</p>

  <p>{{ organizationPostAddress }}</p>

  <p>Email: <a href="mailto:{{ organizationEmail }}" target="_blank">{{ organizationEmail }}</a></p>

</div>
