import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { fadeIn } from '@app/components/animations';
import { IconComponent } from '@app/components/icon/icon.component';
import { ModalModule } from '@app/components/modal/modal.module';
import { UiButtonComponent } from '@app/components/shared/ui-button/ui-button.component';
import { BillingService } from '@app/services';
import { Device, DEVICE } from '@le2xx/ngx-toolkit-device';
import { ChangableComponent } from '@models/changable.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [CommonModule, ModalModule, IconComponent, UiButtonComponent],
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  animations: [
    trigger('expandableState', [transition(':enter', useAnimation(fadeIn))]),
  ],
})
export class SubscriptionComponent
  extends ChangableComponent
  implements OnInit
{
  public modalVisible = false;
  public activePlan: any;
  public plans = [
    { id: 1, label: 'Неделя', text: 'Активировать PLUS на неделю' },
    { id: 2, label: '1 мес', text: 'Активировать PLUS на 1 месяц' },
    { id: 3, label: '3 мес', text: 'Активировать PLUS на 3 месяца' },
    { id: 4, label: '6 мес', text: 'Активировать PLUS на 6 месяцев' },
  ];

  constructor(
    private billingService: BillingService,
    protected readonly cdr: ChangeDetectorRef,
    @Inject(DEVICE) public device: Device
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.billingService.modalVisible$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((visible) => {
        this.modalVisible = visible;
      });
  }

  public onCancel() {
    this.billingService.hidePlansModal();
  }

  public setPlan(plan) {
    this.activePlan = plan;
  }
}
