import { createAction, props } from '@ngrx/store';
import { Account } from 'viksi-models';

export const userLogout = createAction('[Profile] User logout');

export const loadMe = createAction('[Profile] Load me');
export const loadMeSuccess = createAction(
  '[Profile] Load me success',
  props<{ me: Account }>()
);
export const loadMyProfileSuccess = createAction(
  '[Profile] Load my profile success',
  props<{ profile: Account }>()
);

export const setProfile = createAction(
  '[Users] Set profile',
  props<{ profile: Account }>()
);
