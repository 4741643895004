<app-modal *ngIf="modalVisible" [wide]="device.isNormal()" [middle]="!device.isNormal()" (closed)="onCancel()"
           [@expandableState]>
  <app-modal-body>
    <div class="subscription-modal block">
      <div class="logo">
        <app-icon [width]="168" [height]="59" [icon]="'logo-header'"></app-icon>
      </div>

      <div class="plans">
        <span class="plan" [class.active]="plan === activePlan" *ngFor="let plan of plans"
              (click)="setPlan(plan)">{{plan.label}}</span>
      </div>

      <div class="plan-widget">
        <div class="info">
          <app-icon [icon]="'colorful-bg'" [width]="207" [height]="193"></app-icon>
          <div class="plan-info">
            <p>PLUS - профиль дает возможность:</p>
            <ul>
              <li>Ставить лайки всем девушкам</li>
              <li>Писать в личку без взаимной симпатии (Только для статуса “Проведу время”)</li>
              <li>Отправлять фото</li>
            </ul>
          </div>
        </div>
        <div class="price">599 руб / неделя</div>
        <ui-button [class]="'main'"
                   [iconPosition]="'before'"
                   [label]="activePlan ? activePlan.text : 'Попробовать PLUS'">
          <app-icon [icon]="'crown'" [height]="25" [width]="25"></app-icon>
        </ui-button>
      </div>
    </div>
  </app-modal-body>
</app-modal>
