<ng-template [ngIf]="isVisible">
  <ng-content select="app-modal-ad"></ng-content>
</ng-template>

<div class="modal" [class.hide]="!isVisible" *ngIf="isBrowser">
  <div class="modal-virtual-scroll" #scrollHolder>
    <div class="shade" #background
        [style.height.px]="modalBody.offsetHeight * scaleNumber + 150"
        [class.dark]="!canClose || shadeDark"
        [class.gray]="shadeGray"
        [class.hover]="hoverFlag"
        [class.light]="shadeLight">
      <div class="window">
        <div class="grid-wrap">
          <div class="grid-header" (click)="onClose($event)"></div>
          <div class="grid-center" #gridCenter [ngStyle]="{'transform': 'scale(' + scaleNumber + ')'}">
            <div class="grid-left" (click)="onClose($event)"></div>
            <div class="modal-body"
                 #modalBody
                 [class.shadow]="!canClose"
                 [class.short]="short"
                 [class.long]="long"
                 [class.wide]="wide"
                 [class.middle]="middle"
                 [class.narrow]="narrow">
              <div class="tools-block" [style.transform]="'translateY(' + scrollTop + 'px)'">
                <div *ngIf="!canClose" class="close-btn"
                     (click)="onCloseBtn($event)"
                     (mouseover)="hover($event)"
                     (mouseout)="hover($event)">
                  <app-icon class="close-btn-icon" [height]="40" [width]="40" [icon]="'modal-close'"></app-icon>
                </div>
              </div>

              <ng-content select="app-modal-body"></ng-content>
            </div>

            <div class="grid-right" (click)="onClose($event)"></div>
          </div>
          <div class="grid-bottom" [ngStyle]="{'min-height': calcHeader + 'px'}"
               [class.hide-footer]="showToFooter"
               (click)="onClose($event)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
