<ng-template [ngIf]="cover?.link" [ngIfElse]="notLink">
  <a class="on-ui-cover"
     [class.base]="cover?.form === 'base'"
     [class.circle]="cover?.form === 'circle'"
     [routerLink]="cover?.link"
     [attr.alt]="cover?.title"
     [title]="cover?.title">
    <ng-container *ngTemplateOutlet="img"></ng-container>
  </a>
</ng-template>

<ng-template #notLink>
  <div class="on-ui-cover"
       [class.base]="cover?.form === 'base'"
       [class.circle]="cover?.form === 'circle'">
    <ng-container *ngTemplateOutlet="img"></ng-container>
  </div>
</ng-template>

<ng-template #img>
  <ng-template [ngIf]="cover?.img" [ngIfElse]="noCover">
    <ng-template [ngIf]="isBrowser" [ngIfElse]="server">
      <img [lazyLoad]="cover?.img"
           [defaultImage]="defaultImage()"
           [alt]="cover?.title"
           [title]="cover?.title"
           [style.aspect-ratio]="cover?.aspect"
           class="cover">
    </ng-template>

    <ng-template #server>
      <img [src]="cover?.img"
           [alt]="cover?.title"
           [title]="cover?.title"
           class="cover">
    </ng-template>
  </ng-template>

  <ng-template #noCover>
    <ng-template [ngIf]="cover?.type === 'cover'">
      <div class="cover no-cover">
        <svg class="cover-placeholder" viewBox="0 0 25 25" [attr.alt]="cover?.title" [attr.title]="cover?.title">
          <use [attr.xlink:href]="svgsetIconUrl('cover-placeholder')"></use>
        </svg>
      </div>
    </ng-template>

    <ng-template [ngIf]="cover?.type === 'avatar'">
      <div class="cover">
        <svg class="avatar-placeholder" viewBox="0 0 140 140" [attr.alt]="cover?.title" [attr.title]="cover?.title">
          <use [attr.xlink:href]="svgsetIconUrl('avatar-placeholder')"></use>
        </svg>
      </div>
    </ng-template>
  </ng-template>
</ng-template>
