const firebaseConfig = {
  apiKey: 'AIzaSyDRyCwI8aKZ5B0-EoQTSIniQByzMIeiIsE',
  authDomain: 'viksi-666.firebaseapp.com',
  databaseURL: 'https://viksi-666-default-rtdb.firebaseio.com',
  projectId: 'viksi-666',
  storageBucket: 'viksi-666.appspot.com',
  messagingSenderId: '431601894279',
  appId: '1:431601894279:web:7eecb2bcf47c3224198922',
  measurementId: 'G-W2H39TCXXC',
};

export const environment = {
  production: true,
  environment: 'staging',
  application_name: 'viksi666.com',
  access_token_key: 'APP_AUTH_ACCESS',
  account_url: 'https://devb.viksi666.com/web/account/20210921',
  ws_url: 'wss://devb.viksi666.com/ws/20210921',
  assets_url: '',
  android_app_url:
    'https://play.google.com/store/apps/details?id=com.viksi.app&hl=ru',
  ios_app_url:
    'https://apps.apple.com/ru/app/viksi-666/id1642334393?ppid=226e6740-a63f-4c7c-8376-f96173d10b2e',
  messaging_url: 'https://devb.viksi666.com/web/messaging/20210921',
  files_url: 'https://devb.viksi666.com/web/files/20210921',
  store_debug: false,

  firebase: {
    ...firebaseConfig,
    vapidKey:
      'BGnQwCx-xHHlUwnHx1itg5BtrrzQmELfTnsMFFzPVkndYwE59EcOnPQRS6ZTK4J9rK3_kO9nV4H4Y3CBn-lxWa0',
  },
};
