import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { AbstractComponent } from '@models/abstract-component';
import { of, timer } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  mergeAll,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { WebsocketService, AccountService } from '../../services';

type StatusType = 'processing' | 'done' | 'failed';

@Component({
  selector: 'app-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPageComponent extends AbstractComponent implements OnInit {
  public ws_status: StatusType = 'processing';
  public api_status: StatusType = 'processing';
  // public pingTime$ = this.ws.pingTime$;
  public apiTime: number;

  constructor(
    protected ws: WebsocketService,
    protected accountService: AccountService,
    protected inject: Injector
  ) {
    super(inject);
  }

  ngOnInit() {
    of(...[this.watchWsStatus$, this.watchApiStatus$])
      .pipe(mergeAll(), takeUntil(this.destroyed$))
      .subscribe();
  }

  public statusCssClass(status: StatusType): any {
    switch (status) {
      case 'processing':
        return 'yellow';
      case 'done':
        return 'green';
      case 'failed':
        return 'red';
    }
  }

  protected get watchWsStatus$() {
    return this.ws.status$.pipe(
      tap((status) => {
        this.ws_status = status ? 'done' : 'failed';
        this.detectChanges();
      })
    );
  }

  protected get watchApiStatus$() {
    let apiStartTime: number;
    let processing = false;
    return timer(0, 10000).pipe(
      filter(() => !processing),
      switchMap(() => {
        processing = true;
        apiStartTime = Date.now();
        return this.accountService
          .findAccountsByPresets(['online'], {}, { page: 1, pageSize: 1 }, [
            'first_name',
          ])
          .pipe(
            tap(() => {
              this.api_status = 'done';
            }),
            catchError(() => {
              this.api_status = 'failed';
              return of(null);
            }),
            finalize(() => {
              processing = false;
              this.apiTime = Date.now() - apiStartTime;
              this.detectChanges();
            })
          );
      })
    );
  }
}
