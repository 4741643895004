import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PagedResults,
  Pagination,
  FindFileFilter,
  File as IFile,
  DoneFailResponse,
  FileTargetEnum,
  Hash,
  ImageFile,
} from 'viksi-models';
import { FileTypeEnum, MediaFormatEnum } from 'viksi-models/dist';
import { IContentBlock } from './interfaces';

export type FilesBlockType = IContentBlock<IFile, FindFileFilter>;

export interface IMediaFile {
  format: MediaFormatEnum;
  url: string;
}

export interface IImageThumbnail {
  id?: string;
  url?: string;
  size: string;
  width: number;
  height: number;
  round?: boolean;
  format?: string;
}

export interface IFileType {
  id: string;
  author_id: string;
  filename: string;
  type: FileTypeEnum;
  images?: Hash<ImageFile>;
  media?: IMediaFile[];
  storage_file_url?: string; // link to original image (old style)
  isProcessing?: boolean; // show that file is in process now
  parent_file_id?: string; // ID for original user avatar
}

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient) {}

  /** Одно изображение */
  public findFile(id: string): Observable<IFile> {
    const url = `${environment.files_url}/file/${id}`;
    return this.http.get<IFile>(url, { observe: 'body' });
  }

  /**
   * Поиск файла по ID
   *
   * @param id string
   */
  public getFileById(id: string): Observable<IFileType> {
    const url = `${environment.files_url}/file/${id}`;
    return this.http.get<IFileType>(url, { observe: 'response' }).pipe(
      map((response) => response.body)
      // catchError(this.handleError),
    );
  }

  /**
   * Список изображений по заданному фильтру
   */
  public findFiles(
    filter: Partial<FindFileFilter>,
    pagination: Pagination
  ): Observable<PagedResults<IFile>> {
    const url = `${environment.files_url}/files`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http
      .get<PagedResults<IFile>>(url, { params, observe: 'response' })
      .pipe(map(this.handleFilesResponse));
  }

  public uploadFile(file: File, target: FileTargetEnum): Observable<IFile> {
    const url = `${environment.files_url}/file/upload-process?target=${target}`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<IFile>(url, formData, { observe: 'body' });
  }

  public removeFile(file_id: string): Observable<DoneFailResponse> {
    const url = `${environment.files_url}/file/${file_id}`;
    return this.http.delete<DoneFailResponse>(url, { observe: 'body' });
  }

  private handleFilesResponse(
    response: HttpResponse<PagedResults<IFile>>
  ): PagedResults<IFile> {
    return {
      results: response.body.results,
      pagination: response.body.pagination,
    };
  }
}
