import { createReducer, on, Action } from '@ngrx/store';
import { Account } from 'viksi-models';
import { AppState } from '..';
import * as ProfileAction from '../actions/profile.actions';

export interface State {
  me: Account;
  myProfile: Account;
}

const initialState: State = {
  me: null,
  myProfile: null,
};

/* Reducers */
const profileReducer = createReducer(
  initialState,
  on(ProfileAction.userLogout, () => initialState),
  on(ProfileAction.loadMeSuccess, (state, { me }) => ({
    ...state,
    me,
  })),
  on(ProfileAction.loadMyProfileSuccess, (state, { profile }) => ({
    ...state,
    myProfile: profile,
  })),

  on(ProfileAction.setProfile, (state, { profile }) => ({
    ...state,
    myProfile: profile,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}

/* Key */
const thisFeatureKey = 'profile';
export const profileFeatureKey = thisFeatureKey;

/* Selectors */
export const getMe = (state: AppState) => state[thisFeatureKey].me;
export const getMyProfile = (state: AppState) =>
  state[thisFeatureKey].myProfile;
