import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ChangableComponent } from '@models/changable.component';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ui-textarea',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './ui-textarea.component.html',
  styleUrls: ['./ui-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiTextareaComponent),
      multi: true,
    },
  ],
})
export class UiTextareaComponent
  extends ChangableComponent
  implements OnInit, AfterViewInit
{
  @Input() placeholder = '';
  @Input() maxlength = 2000;
  @Input() error = false;

  @Output() changed = new EventEmitter<string>();

  public formControl = new UntypedFormControl();
  public value = '';
  public isDisabled: boolean;
  @ViewChild('shadowText', { static: false }) shadowTextRef: ElementRef;
  public textHeight = 0;

  constructor(protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(filter((value) => value !== this.value))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.value = value;
        this.propagateChange(value);
        this.changed.emit(value);
        this.onChangeText();
      });
  }

  ngAfterViewInit() {
    this.onChangeText();
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.formControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onBlur(): void {
    this.propagateTouched();
  }

  onChangeText() {
    this.textHeight = this.shadowTextRef?.nativeElement?.offsetHeight;
    this.detectChanges();
  }
}
