import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as jwtDecode from 'jwt-decode';
import { Account } from 'viksi-models';
import { AuthService } from '../services';

export const SKIP_AUTHORIZATION = 'X-No-Authorization';

/**
 *  Добавляет заголовок 'Authorization' для авторизации пользователя
 *  Добавляет заголовок 'X-User' для визуальной отладки запросов в случаях, если на клиенте сбои авторизации
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  authPrefix = 'Bearer';

  constructor(
    private auth: AuthService,
  ) {
  }

  public static parseUserFromAccessToken(token: string): Account {
    try {
      const token_decoded = jwtDecode<any>(token);
      if (!token_decoded.subject || !token_decoded.subject.id) {
        console.error('Lingo2AuthTokenInterceptor:parseUserFromAccessToken', 'token is wrong', token);
      }
      return new Account(token_decoded.subject);
    } catch (e) {
      console.error('Lingo2AuthTokenInterceptor:parseUserFromAccessToken', e);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(SKIP_AUTHORIZATION)) {
      const headers = request.headers.delete(SKIP_AUTHORIZATION);
      return next.handle(request.clone({ headers }));
    }

    const accessToken = this.auth.accessToken;
    if (!!accessToken) {
      const user = AuthInterceptor.parseUserFromAccessToken(accessToken);
      const headers = {
        'X-User': user ? user.id : 'none', // TODO !production || debug
        Authorization: `${this.authPrefix} ${accessToken}`
      };
      request = request.clone({
        setHeaders: headers,
      });
    }
    return next.handle(request);
  }
}
