import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from '@app/components/icon/icon.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { ModalStackService } from './modal-stack.service';
import {
  ModalComponent,
  ModalAdComponent,
  ModalBodyComponent,
} from './modal.component';

@NgModule({
  declarations: [ModalComponent, ModalAdComponent, ModalBodyComponent],
  imports: [CommonModule, DirectivesModule, IconComponent],
  exports: [ModalComponent, ModalAdComponent, ModalBodyComponent],
  providers: [ModalStackService],
})
export class ModalModule {}
