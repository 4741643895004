import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AbstractComponent } from '@models/abstract-component';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportPageComponent extends AbstractComponent {
  constructor(protected inject: Injector) {
    super(inject);
  }

  /*
  Центр поддержки:
  https://app.crisp.chat/website/1ad93cb1-931d-48a6-b31e-b1dc0be5bf8a

  TODO получать URL вида
  http://viksi-app.local:4303/help?platform=ios&version=1.2.3&account_id=<guid>
  */

  /*
  Категории
  Purchase / payment issues
  Problem accessing account
  Application crash
  Bug report
  Report cheating / scummer suspect
  Report inappropriate behavior
  Other
  */
}
