import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ModalStackService } from '@app/components/modal/modal-stack.service';
import { ChangableComponent } from '@app/models/changable.component';
import { PlatformService } from '@app/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal-ad',
  template: '<ng-content *ifIsBrowser></ng-content>',
})
export class ModalAdComponent {}

@Component({
  selector: 'app-modal-body',
  template: '<ng-content *ifIsBrowser></ng-content>',
})
export class ModalBodyComponent {}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent
  extends ChangableComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @Input() public canClose: boolean;
  @Input() public static: boolean;
  @Input() public short: boolean;
  @Input() public long: boolean;
  @Input() public wide: boolean;
  @Input() public middle: boolean;
  @Input() public narrow: boolean;
  @Input() public shadeDark: boolean;
  @Input() public shadeLight: boolean;
  @Input() public shadeGray: boolean;
  @Input() public showToFooter: boolean;
  @Output() public closed = new EventEmitter<boolean>();
  public isVisible = true;
  public scrollTop = 0;
  public hoverFlag = false;
  public scaleNumber = 1;
  private modalId: number;
  private heightCenter = 1;
  private is_open = false;
  private readonly element: any;

  @ViewChild('gridCenter', { read: '' }) protected gridCenterRef: ElementRef;
  @ViewChild('scrollHolder', { static: true })
  protected scrollHolder: ElementRef<HTMLElement>;
  @ViewChild('background', { static: true })
  protected background: ElementRef<HTMLElement>;
  @ViewChild('modalBody', { static: true })
  protected modalBody: ElementRef<HTMLElement>;

  public constructor(
    private modalStackService: ModalStackService,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService
  ) {
    super(cdr, platform);
    this.element = el.nativeElement;
    this.canClose = !!this.canClose;
  }

  public get scaleValue() {
    return Math.round(this.scaleNumber * 100);
  }

  public get calcHeader() {
    if (this.scaleNumber > 1) {
      return Math.round(
        (this.scaleNumber - 1) * 10 * (this.heightCenter / 20) + 100
      );
    }
    if (this.showToFooter) {
      return window.innerHeight * 0.6;
    }
    return 100;
  }

  public ngOnInit() {
    if (this.isBrowser) {
      document.querySelector('.app').appendChild(this.element);
    }
    this.modalId = this.modalStackService.pushStack();
    this.modalStackService.updated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.isVisible = this.modalStackService.isVisible(this.modalId);
        this.detectChanges();
      });

    this.open();
  }

  public ngAfterViewInit() {
    this.heightCenter = this.gridCenterRef?.nativeElement?.offsetHeight;
    this.markForCheck();
  }

  public ngAfterViewChecked() {
    this.detectChanges();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    if (this.isBrowser) {
      this.element.remove();
    }
    this.modalStackService.popStack();
    this.close();
  }

  public hover(event) {
    if (event.type === 'mouseover') {
      this.hoverFlag = true;
    } else if (event.type === 'mouseout') {
      this.hoverFlag = false;
    }
  }

  /**
   * open modal
   */
  public open(): void {
    if (!this.is_open) {
      if (this.isBrowser) {
        if (this.element?.style) {
          this.element.style.display = 'block';
        }
        const scrollAllContent = document.querySelector(
          '.app-body-scroll>.ng-scrollbar-wrapper>scrollbar-control'
        );
        if (scrollAllContent) {
          scrollAllContent.setAttribute('style', 'display: none');
        }
      }

      this.setBodyFixed(true);
      this.is_open = true;
    }
  }

  /**
   * close modal
   */
  public close(): void {
    if (this.is_open) {
      if (this.isBrowser) {
        const scrollAllContent = document.querySelector(
          '.app-body-scroll>.ng-scrollbar-wrapper>scrollbar-control'
        );
        if (scrollAllContent) {
          scrollAllContent.setAttribute('style', 'display: flex');
        }
      }

      if (!this.modalStackService.visibleCount) {
        this.setBodyFixed(false);
      }
      this.is_open = false;
    }
  }

  public setBodyFixed(state: boolean) {
    this.setBodyClass('fixed', state);
  }

  public setBodyClass(className: string, state: boolean) {
    if (this.isBrowser) {
      const body = document.getElementsByTagName('body')[0];
      if (state) {
        body.classList.add(className);
      } else {
        body.classList.remove(className);
      }
    }
  }

  public onClose(event?: Event) {
    if (event) {
      event.preventDefault();
      // С event.cancelBubble = true не срабатывает clickOutside в дропдаунах зеленой панели
      // event.cancelBubble = true;
    }
    if (this.canClose && !this.static) {
      this.closed.emit(true);
    }
  }

  public onCloseBtn(event?: Event) {
    if (event) {
      event.preventDefault();
      event.cancelBubble = true;
    }
    this.closed.emit(true);
  }

  public scalePlus() {
    if (this.scaleNumber < 1.5) {
      this.scaleNumber = Math.round((this.scaleNumber + 0.1) * 10) / 10;
    }
  }

  public scaleMinus() {
    if (this.scaleNumber > 0.5) {
      this.scaleNumber = Math.round((this.scaleNumber - 0.1) * 10) / 10;
    }
  }
}
