import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AbstractComponent } from '@models/abstract-component';

@Component({
  selector: 'app-terms-of-use-page',
  templateUrl: './terms-of-use-page.component.html',
  styleUrls: ['./terms-of-use-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfUsePageComponent extends AbstractComponent {
  public organizationName = 'Global-77 llc';
  public organizationEmail = 'info@viksi666.com';
  public organizationPostAddress = 'Yerevan , 13 Argishti str., Apt. 39,AM';
  public organizationHomeUrl = 'viksi666.com';

  constructor(protected inject: Injector) {
    super(inject);
  }
}
