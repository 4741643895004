import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { fadeIn, slideIn } from '@app/components/animations';
import { FileUploaderComponent } from '@app/components/auth-modal/file-uploader/file-uploader.component';
import { PurposeFormComponent } from '@app/components/auth-modal/purpose-form/purpose-form.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { SpinnersModule } from '@app/components/shared/spinners/spinners.module';
import { OnUiButtonState } from '@app/components/shared/ui-button/ui-button.component';
import { UiButtonComponent } from '@app/components/shared/ui-button/ui-button.component';
import { UiTextareaComponent } from '@app/components/shared/ui-textarea/ui-textarea.component';
import {
  AccountService,
  AuthService,
  ConfigService,
  IFileType,
  IPWhoisResponse,
} from '@app/services';
import { NgxIntlTelInputModule } from '@le2xx/ngx-intl-tel-input';
import { CountryISO } from '@le2xx/ngx-intl-tel-input';
import { ChangableComponent } from '@models/changable.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { Subscription, timer } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import {
  AccountInterest,
  PhoneVerificationResponse,
  ReferencesCombined20210921Response,
  VerificationMediaEnum,
} from 'viksi-models';
import { Account, AppearanceType, Purpose } from 'viksi-models/dist';

function range(start, end) {
  return [...Array(end - start + 1).keys()].map((_, idx) => start + idx);
}
interface IStepInfo {
  title?: string;
  subTitle?: string;
  actionLabel?: string;
  showActions?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'app-auth-modal',
  standalone: true,
  imports: [
    CommonModule,
    UiButtonComponent,
    UiTextareaComponent,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgOtpInputModule,
    PurposeFormComponent,
    IconComponent,
    CountdownModule,
    FileUploaderComponent,
    SpinnersModule,
    RoundProgressModule,
  ],
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandableState', [transition(':enter', useAnimation(fadeIn))]),
  ],
})
export class AuthModalComponent extends ChangableComponent implements OnInit {
  @Input() set account(me: Account) {
    this._me = me;
  }

  public get account() {
    return this._me;
  }

  @Input() set notFilled(notFilled: boolean) {
    if (notFilled) {
      this.step = 4;
      this.detectChanges();
    }
  }

  @Output() checkStep = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();

  private _me: Account;
  public step = 1;
  public countryISO = CountryISO;
  public form: UntypedFormGroup;
  public isCodeError = false;
  public references: ReferencesCombined20210921Response;
  public interests: AccountInterest[];
  public weightOptions = range(50, 500);
  public heightOptions = range(120, 220);

  public verification: PhoneVerificationResponse;
  public verification_text: string;
  public delay: number;
  public error: string;
  public loading = false;
  public purpose: Purpose;
  public buttonState: OnUiButtonState = 'default';

  private delay$$: Subscription;
  private maxFilesCount = 3;

  constructor(
    protected fb: UntypedFormBuilder,
    protected router: Router,
    private authService: AuthService,
    private configService: ConfigService,
    private accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit() {
    if (!this._me) {
      this.step = 1;
    }

    this.form = this.fb.group({
      mobile_phone: ['', [Validators.required]],
      purpose: ['', [Validators.required]],
      about: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      sexuality: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      birth_date: ['', [Validators.required]],
      appearance: ['', [Validators.required]],
      eyes: ['', [Validators.required]],
      hair: ['', [Validators.required]],
      physique: ['', [Validators.required]],
      weight: ['', [Validators.required]],
      height: ['', [Validators.required]],
      interests: this.fb.array([]),
      family: ['', [Validators.required]],
      children: ['', [Validators.required]],
      alcohol: ['', [Validators.required]],
      smoking: ['', [Validators.required]],
      accommodation: ['', [Validators.required]],
      city: ['', [Validators.required]],
      userpic_id: ['', [Validators.required]],
      images: this.fb.array([null]),
    });

    this.configService
      .getReferences()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((references) => {
        this.references = references;
        this.detectChanges();
      });

    this.getInterests();
  }

  public get ifLastItem() {
    return this.accountFiles.length === this.maxFilesCount + 1;
  }

  public get interestsArray(): UntypedFormArray {
    return this.form.get('interests') as UntypedFormArray;
  }

  public addInterest(interest: AccountInterest) {
    const ids = this.form.get('interests').value.map((el) => el.id);
    const index: number = ids.indexOf(interest.id);

    if (index === -1) {
      this.interestsArray.push(this.createInterestOption(interest));
    } else {
      this.interestsArray.removeAt(index);
    }
  }

  protected createInterestOption(interest: AccountInterest) {
    return this.fb.group({
      id: interest.id,
      title: interest.title,
    });
  }

  public isActiveInterest(id: number) {
    const ids = this.form.get('interests').value.map((el) => el.id);
    return ids.indexOf(id) !== -1;
  }

  public get accountFiles(): UntypedFormArray {
    return this.form.get('images') as UntypedFormArray;
  }

  public onChangedMedia(args: IFileType, idx: number) {
    const len = this.accountFiles.length;
    if (len - 1 !== idx && args) {
      return;
    }

    if (args) {
      this.addMedia();
    } else {
      this.removeMedia(idx);
    }
  }

  private static mediaFormControl(value: IFileType = null): AbstractControl {
    return new UntypedFormControl(value);
  }

  public addMedia() {
    this.accountFiles.push(AuthModalComponent.mediaFormControl());
  }

  public removeMedia(index: number) {
    this.accountFiles.removeAt(index);
    this.form.markAsDirty();
  }

  public getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        this.callApi(longitude, latitude);
      });
    } else {
      console.log('No support for geolocation');
    }

    this.accountService
      .getCoordinatesByIP()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: IPWhoisResponse) => {
        this.form.get('city').setValue(res.city);
        this.detectChanges();
      });
  }

  public callApi(longitude: number, latitude: number) {
    this.accountService
      .updateGeoLocation({ geo_longitude: longitude, geo_latitude: latitude })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detectChanges());
  }

  public changeStep() {
    switch (this.step) {
      case 10:
        this.getLocation();
        this.step += 1;
        break;
      case 12:
        this.updateAccount();
        break;
      case 2:
        this.sendCode();
        break;
      default:
        this.step += 1;
    }
  }

  public goBack() {
    this.step -= 1;
  }

  public updateAccount() {
    if (this.buttonState === 'in-progress') {
      return;
    }
    this.buttonState = 'in-progress';
    const images = this.form.get('images').value.filter((el) => !!el);
    if (images.length) {
      this.form.get('userpic_id').patchValue(images[0]?.id);
    }

    const data = this.form.value;
    delete data['images'];
    delete data['mobile_phone'];
    data['city'] = { id: null, title: data['city'] };

    this.accountService
      .updateAccount(this.account.id, data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.buttonState = 'default';
        this.account = res;
        this.authService.refreshAccount(res.id);
        this.onClose.emit(true);
        this.detectChanges();
      });
  }

  public sendCode() {
    this.buttonState = 'in-progress';
    this.authService
      .verifyPhoneAuth(this.form.get('mobile_phone').value?.e164Number)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.buttonState = 'default';
          this.step += 1;
          this.onPhoneVerificationResponse(res);
          this.detectChanges();
        },
        error: (err) => {
          this.onPhoneVerificationError(err);
          console.log('error', err);
        },
      });
  }

  public retry(verification_code_media: VerificationMediaEnum) {
    this.loading = true;
    this.authService
      .verifyPhoneRetry({
        verification_id: this.verification.verification_id,
        verification_code_media,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.onPhoneVerificationResponse(res);
          this.detectChanges();
        },
        error: (err) => {
          this.onPhoneVerificationError(err);
          console.log('error', err);
        },
      });
  }

  protected onPhoneVerificationResponse(response: PhoneVerificationResponse) {
    this.verification = response;
    this.delay = response.retry_timeout_sec;
    const verification_text = this.verification.verification_waiting_text;

    if (this.delay$$) {
      this.delay$$.unsubscribe();
    }

    this.delay$$ = timer(0, 1000)
      .pipe(
        take(this.delay),
        tap((x) => {
          this.delay--;
          this.verification_text = verification_text.replace(
            '{{delay}}',
            this.delay.toString()
          );
          this.detectChanges();
        }),
        finalize(() => {
          this.verification_text = this.verification.verification_ready_text;
          this.detectChanges();
        })
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  protected onPhoneVerificationError(err) {
    this.error = err.message;
  }

  public onOtpChange(code: string) {
    this.isCodeError = false;
    if (code.length === this.verification.verification_code_length) {
      this.authUsingVerification(code);
    }
  }

  public authUsingVerification(code) {
    this.error = null;
    this.authService
      .authUsingVerification(this.verification.verification_id, code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.checkStep.emit(true);
          this.detectChanges();
        },
        error: (err) => {
          this.isCodeError = true;
          this.error = err.details.error;
          console.log('error', err);
        },
      });
  }

  public setPurpose(purpose: Purpose) {
    this.purpose = purpose;
    this.form.get('purpose').setValue(purpose);
    this.detectChanges();
  }

  public get getStepInfo(): IStepInfo {
    switch (this.step) {
      case 1:
        return { actionLabel: 'Вход по номеру телефона' };
      case 2:
        return {
          title: 'Мой номер',
          actionLabel: 'Получить код',
          disabled: this.form.get('mobile_phone').invalid,
        };
      case 3:
        return { title: 'Мой код', showActions: true };
      case 4:
        return {
          title: 'Цель знакомства:',
          subTitle: 'Можно выбрать только одну',
          actionLabel: 'Продолжить',
          disabled:
            this.form.get('purpose').invalid || this.form.get('about').invalid,
        };
      case 5:
        return {
          actionLabel: 'Продолжить',
          disabled:
            this.form.get('gender').invalid ||
            this.form.get('sexuality').invalid,
        };
      case 6:
        return {
          title: 'Имя и дата рождения',
          actionLabel: 'Продолжить',
          disabled:
            this.form.get('first_name').invalid ||
            this.form.get('birth_date').invalid,
        };
      case 7:
        return {
          title: 'Коротко о себе',
          actionLabel: 'Продолжить',
          disabled:
            this.form.get('appearance').invalid ||
            this.form.get('eyes').invalid ||
            this.form.get('hair').invalid ||
            this.form.get('physique').invalid ||
            this.form.get('weight').invalid ||
            this.form.get('height').invalid,
        };
      case 8:
        return {
          title: 'Интересы',
          actionLabel: 'Продолжить',
          disabled: !this.interestsArray.length,
        };
      case 9:
        return {
          title: 'Дополнительно',
          actionLabel: 'Продолжить',
          disabled:
            this.form.get('family').invalid ||
            this.form.get('children').invalid ||
            this.form.get('alcohol').invalid ||
            this.form.get('smoking').invalid ||
            this.form.get('accommodation').invalid,
        };
      case 10:
        return {
          title: 'Moи фото',
          actionLabel: 'Продолжить',
          disabled: !this.accountFiles.value.filter((el) => !!el).length,
        };
      case 11:
        return {
          title: 'Где ты находишься?',
          subTitle:
            'Разреши приложению определить твое местоположение, чтобы показывать людей рядом с тобой',
          actionLabel: 'Продолжить',
        };
      case 12:
        return {
          title: 'Добро пожаловать в Viksi666',
          subTitle: 'Пожалуйста, соблюдайте следующие правила:',
          actionLabel: 'Начать знакомиться',
        };
      default:
        return { title: '' };
    }
  }

  public setGender(owner, gender) {
    if (owner === 'my') {
      this.form.get('gender').setValue(gender);
    }
    if (owner === 'preferences') {
      this.form.get('sexuality').setValue(gender);
    }
  }

  public setAppearance(control: string, type: AppearanceType) {
    this.form.get(control).setValue(type);
  }

  public getInterests() {
    this.configService
      .getInterests({ page: 1, pageSize: 500, total: 0, totalPages: 0 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((interests) => {
        this.interests = interests.results;
        this.detectChanges();
      });
  }
}
