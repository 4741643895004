import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IconsService } from '@app/services';
import { PlatformService } from '@app/services';
import { ChangableComponent } from '@models/changable.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

export type OnUICoverType = 'cover' | 'avatar';
export type OnUiCoverForm = 'base' | 'circle' | 'none';

export interface OnUiCover {
  type?: OnUICoverType;
  img?: string;
  title?: string;
  link?: string;
  form?: OnUiCoverForm;
  aspect?: string;
}

@Component({
  selector: 'ui-cover',
  standalone: true,
  imports: [CommonModule, RouterModule, LazyLoadImageModule],
  templateUrl: './ui-cover.component.html',
  styleUrls: ['./ui-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCoverComponent extends ChangableComponent {
  @Input() public set cover(cover: OnUiCover) {
    this.innerCover = {
      ...cover,
      type: cover?.type || 'cover',
      title: cover?.title || ' ',
      form: cover?.form || 'base',
      aspect: cover?.aspect || '1/1',
    };
  }
  public get cover(): OnUiCover {
    return this.innerCover;
  }

  public svgsetIconUrl = IconsService.svgsetIconUrl;

  private innerCover: OnUiCover;

  constructor(
    private _sanitizer: DomSanitizer,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService
  ) {
    super(cdr, platform);
  }

  public defaultImage(): string {
    // TODO белый пиксель, для красивой загрузки
    const safeResourceUrl: SafeResourceUrl =
      this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64, iVBORw0KGgoAAAANSUh' +
          'EUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII='
      );
    return this._sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      safeResourceUrl
    );
  }
}
