/* tslint:disable:no-string-literal */
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService } from '@app/services';
import { environment } from '@env/environment';
import { AbstractComponent } from '@models/abstract-component';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { SweetAlertOptions } from 'sweetalert2';
import { AccountSubscription, SubscriptionStatusEnum } from 'viksi-models';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPageComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  @ViewChild('swalRef')
  public readonly swalRef!: SwalComponent;
  public swalOptions: SweetAlertOptions = {
    // TODO titleText: 'Подписка на premium доступ к сервису ' + environment.application_name,
  };
  public accountSubscription: AccountSubscription;
  public accountSubscriptionLoaded$ = new Subject<boolean>();
  public providerReady$ = new Subject<boolean>();

  private id: string;

  constructor(
    protected billing: BillingService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected inject: Injector
  ) {
    super(inject);
  }

  ngOnInit() {
    // document.getElementsByTagName('body')[0].style.background = '#000';

    combineLatest([this.accountSubscriptionLoaded$, this.providerReady$])
      .pipe(
        filter(([loaded, providerReady]) => loaded && providerReady),
        take(1),
        tap(() => {
          this.detectChanges();
          this.subscribe();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    this.loadJsSDK();

    this.watchRouteChanged$.pipe(takeUntil(this.destroyed$)).subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    //  document.getElementsByTagName('body')[0].style.background = null;
  }

  public get canSubscribe(): boolean {
    return this.accountSubscription?.status === SubscriptionStatusEnum.pending;
  }

  /** создать подписку (регулярный платёж) */
  public subscribe() {
    if (!this.canSubscribe) {
      return;
    }
    const id = this.accountSubscription.id;
    this.billing
      .cloudpaymentsRecurringChargeOptions(id)
      .pipe(
        tap((options) => {
          const widget = new window['cp'].CloudPayments({ language: 'ru-RU' });
          widget.charge(
            {
              ...options,
              skin: 'modern', // дизайн виджета
              autoClose: 5, // время в секундах до авто-закрытия виджета
            },
            /* onSuccess */ (_options) => {
              console.log(
                'CloudPayments -> widget -> subscribe -> complete',
                _options
              );
              // действие при успешной оплате
              this.router.navigate(['subscription', id, 'success']).then();
              this.showAlert({
                ...this.swalOptions,
                icon: 'success',
                text:
                  'Подписка оформлена. Можете закрыть эту вкладку браузера и вернуться в приложение ' +
                  environment.application_name,
              });
              this.detectChanges();
            },
            /* onFail */ (reason, _options) => {
              if (reason === 'User has cancelled') {
                console.log(
                  'CloudPayments -> widget -> subscribe -> cancel',
                  reason,
                  _options
                );
                // действие при отмене оплате
                this.router.navigate(['subscription', id, 'cancel']).then();
                this.showAlert({
                  ...this.swalOptions,
                  icon: 'info',
                  text: 'Оформление подписки отменено. Если Вы передумали - обновите страницу.',
                });
              } else {
                console.log(
                  'CloudPayments -> widget -> subscribe -> fail',
                  reason,
                  _options
                );
                // действие при неуспешной оплате
                this.router.navigate(['subscription', id, 'fail']).then();
                this.showAlert({
                  ...this.swalOptions,
                  icon: 'error',
                  text:
                    'Ошибка оформления подписки. Вернитесь в приложение ' +
                    +environment.application_name +
                    ' и попробуйте оформить подписку ещё раз или выбрать другой способ оплаты',
                });
              }
              this.detectChanges();
            }
          );
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  protected loadJsSDK() {
    if (window['cp']) {
      this.providerReady$.next(true);
      return;
    }
    // <script src='https://widget.cloudpayments.ru/bundles/cloudpayments.js'></script>
    const script = document.createElement('script');
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
    script.addEventListener('load', () => {
      this.providerReady$.next(true);
    });
    document.getElementsByTagName('body')[0].appendChild(script);
  }

  protected loadAccountSubscription(id: string) {
    this.billing
      .findAccountSubscription(id, null)
      .pipe(
        tap((accountSubscription) => {
          this.accountSubscription = accountSubscription;

          if (accountSubscription.status !== SubscriptionStatusEnum.pending) {
            // не подлежит оплате
            this.router.navigate(['subscription', id, 'fail']).then();
            this.showAlert({
              ...this.swalOptions,
              // TODO titleText: accountSubscription.subscription.description.full_title_html,
              icon: 'info',
              text:
                'Ошибка оформления подписки. Вернитесь в приложение ' +
                environment.application_name +
                ' и попробуйте оформить подписку ещё раз или выбрать другой способ оплаты',
            });
            this.detectChanges();
            return;
          }

          this.accountSubscriptionLoaded$.next(true);
          this.detectChanges();
        }),
        catchError((err) => {
          console.log('loadAccountSubscription -> fail', err);

          // действие при ошибке загрузки подписки
          this.router.navigate(['subscription', id, 'fail']).then();
          this.showAlert({
            ...this.swalOptions,
            icon: 'error',
            text:
              'Ошибка оформления подписки. Вернитесь в приложение ' +
              environment.application_name +
              ' и попробуйте оформить подписку ещё раз или выбрать другой способ оплаты',
          });
          this.detectChanges();
          return of(null);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  protected showAlert(options: SweetAlertOptions) {
    this.swalRef
      .update(options)
      .then(() => this.swalRef.fire().then(() => true));
  }

  protected get watchRouteChanged$() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.loadAccountSubscription(this.id);
    }

    return this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('id')),
      filter((id) => id !== this.id),
      tap((id) => {
        this.id = id;
        this.loadAccountSubscription(id);
      })
    );
  }
}
