import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {SanitizeHtmlPipe} from './sanitize-html.pipe';
import { UnixtimeToDatePipe } from './unixtime-to-date.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UnixtimeToDatePipe,
    SanitizeHtmlPipe,
  ],
  exports: [
    UnixtimeToDatePipe,
    SanitizeHtmlPipe,
  ],
})
export class PipesModule { }
