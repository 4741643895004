<div [formGroup]="form" class="auth-modal" [class.step-1]="step===1" [@expandableState]>
  <div class="back-button" *ngIf="step !== 1 && step !== 2 && step !== 4" (click)="goBack()">
    <app-icon class="back-button-icon" [width]="11" [height]="22" [icon]="'back'"></app-icon>
  </div>

  <div class="close-button" *ngIf="step===1" (click)="onClose.emit(false)">
    <app-icon class="close-btn-icon" [height]="40" [width]="40" [icon]="'modal-close'"></app-icon>
  </div>

  <div *ngIf="step!==1 && getStepInfo.title" class="title-wrap">
    <p class="title">{{getStepInfo.title}}</p>
    <p class="sub-title">{{getStepInfo.subTitle}}</p>
  </div>

  <div [ngStyle]="{'display': step===2 ? 'flex' : 'none'}" class="block" [@expandableState]>

    <div class="auth-input-field">
      <ngx-intl-tel-input
        [cssClass]="'custom'"
        [searchCountryFlag]="true"
        [enablePlaceholder]="true"
        [selectFirstCountry]="false"
        [maxLength]="15"
        [separateDialCode]="true"
        [selectedCountryISO]="countryISO.Russia"
        [phoneValidation]="true"
        [inputId]="'country-code-wrap'"
        formControlName="mobile_phone"></ngx-intl-tel-input>

      <p class="description">
        Введите номер телефона
      </p>

    </div>
  </div>

  <div *ngIf="step===3" class="block" [@expandableState]>
    <div class="code-wrap">
      <ng-template [ngIf]="loading">
        <spinner1></spinner1>
      </ng-template>
      <ng-template [ngIf]="!loading">
        <ng-otp-input (onInputChange)="onOtpChange($event)"
                      [config]="{allowNumbersOnly: true, length: verification.verification_code_length}"></ng-otp-input>
      </ng-template>
      <p *ngIf="error" class="error">{{error}}</p>
      <p class="description">
        {{verification_text}}
      </p>

      <p *ngIf="verification.retry_alternative" (click)="retry(verification.retry_alternative.verification_code_media)"
         class="link mt-5">{{verification.retry_alternative?.title}}</p>

      <p *ngIf="verification.retry" (click)="retry(verification.retry?.verification_code_media)"
         [class.disabled]="delay" class="link mt-3">{{verification.retry?.title}}</p>
    </div>
    <div></div>
  </div>

  <div *ngIf="step===4" class="block" [@expandableState]>
    <div class="about">
      <app-purpose-form [purposes]="references?.purpose" [purpose]="purpose"
                        (changed)="setPurpose($event)"></app-purpose-form>
      <p class="sub-title">Пару слов о себе и намерениях:</p>
      <ui-textarea [maxlength]="100" class="textarea" formControlName="about"
                   placeholder="Например: Я девочка-припевочка, хочу найти мальчика который будет покупать мне брильянтики">
      </ui-textarea>
    </div>
  </div>

  <div *ngIf="step===5" class="block" [@expandableState]>
    <div class="preferences">
      <p class="title">Я</p>
      <div class="gender-block">
        <div class="gender male" [class.active]="form.get('gender').value===1" (click)="setGender('my', 1)">
          <app-icon [width]="49" [height]="72" [icon]="'male'"></app-icon>
          <span>Мужчина</span>
        </div>
        <div class="gender female" [class.active]="form.get('gender').value===2" (click)="setGender('my', 2)">
          <app-icon [width]="49" [height]="72" [icon]="'female'"></app-icon>
          <span>Женщина</span>
        </div>
      </div>
      <p class="title">Ищу</p>
      <div class="gender-block">
        <div class="gender male" [class.active]="form.get('sexuality').value===1" (click)="setGender('preferences', 1)">
          <app-icon [width]="49" [height]="72" [icon]="'male'"></app-icon>
          <span>Мужчина</span>
        </div>
        <div class="gender female" [class.active]="form.get('sexuality').value===2"
             (click)="setGender('preferences', 2)">
          <app-icon [width]="49" [height]="72" [icon]="'female'"></app-icon>
          <span>Женщина</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step===6" class="block" [@expandableState]>
    <p>Так оно будет отображаться в профиле и каталоге</p>
    <input type="text" formControlName="first_name" placeholder="Напиши свое имя или ник"/>
    <p class="mt-5">Другие пользователи увидят только твой возраст</p>
    <input type="date" formControlName="birth_date" placeholder="Выбрать дату рождения"/>
  </div>

  <div *ngIf="step===7" class="block" [@expandableState]>
    <div class="appearance">
      <p class="label">Тип внешности</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('appearance').value===type"
              *ngFor="let type of references?.appearance_type"
              (click)="setAppearance('appearance', type)">
          {{type.title}}
        </span>
      </div>
      <p class="label">Цвет глаз</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('eyes').value===type"
              *ngFor="let type of references?.appearance_eyes"
              (click)="setAppearance('eyes', type)">
          {{type.title}}
        </span>
      </div>
      <p class="label">Цвет волос</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('hair').value===type"
              *ngFor="let type of references?.appearance_hair"
              (click)="setAppearance('hair', type)">
          {{type.title}}
        </span>
      </div>
      <p class="label">Телосложение</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('physique').value===type"
              *ngFor="let type of references?.appearance_physique"
              (click)="setAppearance('physique', type)">
          {{type.title}}
        </span>
      </div>

      <div class="appearance-2">
        <div>
          <p class="label">Вес</p>
          <select formControlName="weight">
            <option *ngFor="let option of weightOptions" [value]="option">{{ option }}</option>
          </select>
        </div>

        <div>
          <p class="label">Рост</p>
          <select formControlName="height">
            <option *ngFor="let option of heightOptions" [value]="option">{{ option }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step===8" class="block" [@expandableState]>
    <div class="interests">
      <div class="appearance-block">
        <span class="type"
              *ngFor="let interest of interests" (click)="addInterest(interest)"
              [class.active]="isActiveInterest(interest.id)">
          {{interest.title}}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="step===9" class="block" [@expandableState]>
    <div class="appearance">
      <p class="label">Семейное положение</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('family').value===type"
              (click)="setAppearance('family', type)"
              *ngFor="let type of references?.family_status">
          {{type.title}}
        </span>
      </div>
      <p class="label">Дети</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('children').value===type"
              (click)="setAppearance('children', type)"
              *ngFor="let type of references?.children">
          {{type.title}}
        </span>
      </div>
      <p class="label">Отношение к алкоголю</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('alcohol').value===type"
              (click)="setAppearance('alcohol', type)"
              *ngFor="let type of references?.alcohol_attitude">
          {{type.title}}
        </span>
      </div>

      <p class="label">Отношение к курению</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('smoking').value===type"
              (click)="setAppearance('smoking', type)"
              *ngFor="let type of references?.smoking_attitude">
          {{type.title}}
        </span>
      </div>
      <p class="label">С кем живет</p>
      <div class="appearance-block">
        <span class="type"
              [class.active]="form.get('accommodation').value===type"
              (click)="setAppearance('accommodation', type)"
              *ngFor="let type of references?.accommodation">
          {{type.title}}
        </span>
      </div>

    </div>
  </div>

  <div *ngIf="step===10" class="block" [@expandableState]>
    <div class="images-wrap" formArrayName="images">
      <ng-container *ngFor="let media of accountFiles.controls; let idx = index; let last = last">
        <div class="attachment-item" *ngIf="!(last && ifLastItem)">
          <app-file-uploader [formControlName]="idx" (changed)="onChangedMedia($event, idx)"
                             [last]="ifLastItem"></app-file-uploader>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="step===11" class="block" [@expandableState]>
    <app-icon [width]="110" [height]="110" [icon]="'location'"></app-icon>
    <input class="mt-4" type="text" formControlName="city" placeholder="Ваш город"/>
  </div>

  <div *ngIf="step===12" class="block" [@expandableState]>
    <div class="final-block">
      <div class="block">
        <app-icon [width]="20" [height]="20" [icon]="'check'"></app-icon>
        <div>
          <div class="info">Будьте собой</div>
          <div class="info-2">Ваши фото, возраст и информация о себе должны быть настоящими</div>
        </div>
      </div>
      <div class="block">
        <app-icon [width]="20" [height]="20" [icon]="'check'"></app-icon>
        <div>
          <div class="info">Ведите себя достойно</div>
          <div class="info-2">Уважайте других и относитесь к ним так, как вы бы хотели, чтобы они относились к вам</div>
        </div>
      </div>
      <div class="block">
        <app-icon [width]="20" [height]="20" [icon]="'check'"></app-icon>
        <div>
          <div class="info">Помните о безопасности</div>
          <div class="info-2">Не торопитесь делиться личной информацией. Безопасность превыше всего</div>
        </div>
      </div>
      <div class="block">
        <app-icon [width]="20" [height]="20" [icon]="'check'"></app-icon>
        <div>
          <div class="info">Действуйте решительно</div>
          <div class="info-2">Всегда сообщайте о неподобающем поведении</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="getStepInfo.actionLabel || getStepInfo.showActions" class="actions-wrap">
    <p *ngIf="step===12">Нажимая на кнопку я подтверждаю что мне есть 18 лет</p>
    <div *ngIf="step>1 && step<12" class="progress-wrapper">
      <div class="current">{{step - 1}}/10</div>
      <round-progress
        [current]="step-1"
        [max]="10"
        [radius]="30"
        [stroke]="3"
        [rounded]="true"
        [color]="'#FF3366'"
        [background]="'#FFB8C9'">
      </round-progress>
    </div>
    <ui-button *ngIf="getStepInfo.actionLabel" [class]="'main'"
               [disabled]="getStepInfo.disabled"
               [state]="buttonState"
               [label]="getStepInfo.actionLabel"
               (click)="changeStep()">
    </ui-button>
  </div>
</div>
