<div class="block1">
  <div class="col1"></div>
  <div class="col2">
      <svg class="logo">
        <use [attr.xlink:href]="svgsetIcon('logo')"></use>
      </svg>
      <svg class="main-logo">
        <use [attr.xlink:href]="svgsetIcon('main-logo')"></use>
      </svg>
    <div class="text1">{{ say('landing-home1.title0') }}</div>
  </div>
</div>

<div class="block2">
  <div class="col1">
    <div class="bg"></div>
    <div class="img1"></div>
    <div class="img2"></div>
  </div>
  <div class="col2">
    <div class="col2-content">
      <div class="text1">{{ say('landing-home1.title1') }}</div>
      <div class="text2">{{ say('landing-home1.title2') }}</div>
    </div>
  </div>
</div>

<div class="block3">
  <div class="stores">
    <a href="{{ ios_app_url }}" aria-label="App Store" title=""><img src="/assets/images/appstore.svg" alt="" width="157" height="47"/></a>
    <a href="{{ android_app_url }}" aria-label="Play Market" title=""><img src="/assets/images/google-play.svg" alt="" width="157" height="47"/></a>
  </div>

  <div class="text1">{{ say('landing-home1.text5') }}</div>

  <div class="providers">
    <svg class="apple-pay">
      <use [attr.xlink:href]="svgsetIcon('apple-pay')"></use>
    </svg>

    <svg class="google-pay">
      <use [attr.xlink:href]="svgsetIcon('google-pay')"></use>
    </svg>

    <svg *ngIf="useCloudPayments" class="cloud-payment">
      <use [attr.xlink:href]="svgsetIcon('cloud-payment')"></use>
    </svg>

  </div>
</div>
