import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import {
  MainComponent,
  PrivacyPageComponent,
  StatusPageComponent,
  SubscriptionPageComponent,
  SupportPageComponent,
  TermsOfUsePageComponent,
} from './pages';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: MainComponent,
      },
      {
        path: 'help',
        component: SupportPageComponent,
      },
      {
        path: 'privacy',
        component: PrivacyPageComponent,
      },
      {
        path: 'terms-of-use',
        component: TermsOfUsePageComponent,
      },
      {
        path: 'status',
        component: StatusPageComponent,
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'feed',
        loadChildren: () =>
          import('./pages/feed/feed.module').then((m) => m.FeedModule),
      },
      {
        path: 'sympathies',
        loadChildren: () =>
          import('./pages/sympathies/sympathies.module').then(
            (m) => m.SympathiesModule
          ),
      },
      {
        path: 'desired',
        loadChildren: () =>
          import('./pages/desired/desired.module').then((m) => m.DesiredModule),
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./pages/chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
    ],
  },
  {
    path: 'subscription/:id',
    component: SubscriptionPageComponent,
    children: [
      {
        path: 'success',
        component: SubscriptionPageComponent,
      },
      {
        path: 'fail',
        component: SubscriptionPageComponent,
      },
      {
        path: 'cancel',
        component: SubscriptionPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
