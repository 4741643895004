import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { IconsService } from '@app/services/icons.service';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() width;
  @Input() height;
  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit() {}
}
