import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  AccountFilterSet,
  AccountInterest,
  PagedResults,
  Pagination,
  ReferencesCombined20210921Response,
} from 'viksi-models';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private http: HttpClient) {}

  public getReferences(): Observable<ReferencesCombined20210921Response> {
    const url = `${environment.account_url}/reference/combined`;
    return this.http.get<ReferencesCombined20210921Response>(url, {
      observe: 'body',
    });
  }

  public getInterests(
    pagination: Pagination,
    term?: string
  ): Observable<PagedResults<AccountInterest>> {
    const url = `${environment.account_url}/reference/interests`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString());
    if ((term || '').length) {
      params = params.set('term', term);
    }
    return this.http.get<PagedResults<AccountInterest>>(url, {
      params,
      observe: 'body',
    });
  }

  public getFilters(): Observable<AccountFilterSet[]> {
    const url = `${environment.account_url}/reference/filters`;
    return this.http.get<AccountFilterSet[]>(url, { observe: 'body' });
  }
}
