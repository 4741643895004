<div class="app">
  <header class="header">
    <nav>
      <div class="container">
        <div class="header_menu">
          <div class="sidenav">
            <input id="sidenav__toggle" type="checkbox"/>
            <label class="sidenav__icon" for="sidenav__toggle">
              <span></span>
            </label>
            <ul class="sidenav__menu">
              <li><a class="menu__item" href="/">dating</a></li>
              <li><a class="menu__item" href="/privacy">privacy</a></li>
              <li><a class="menu__item" href="/terms-of-use">terms</a></li>
              <li><a class="menu__item" href="/help">support</a></li>
            </ul>
          </div>

          <div class="logo" [routerLink]="'/'">
            <svg>
              <use [attr.xlink:href]="svgsetIcon('logo-header')"></use>
            </svg>
          </div>
          <div class="navigation">
            <ul class="menu menu_top">
              <ng-container *ngIf="!me; else menu_auth">
              <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['']" [routerLinkActiveOptions]="{ exact: true }" class="menu_link">dating</a></li>
              <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['privacy']" class="menu_link">privacy</a></li>
              <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['terms-of-use']" class="menu_link">terms</a></li>
              <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['help']" class="menu_link">support</a></li>
              </ng-container>
            </ul>
          </div>
          <app-me></app-me>
        </div>
      </div>
    </nav>
  </header>

  <div class="content">
    <a name="top"></a>
    <router-outlet></router-outlet>
  </div>

  <footer class="footer" *ngIf="is_footer">
    <div class="container">
      <div class="f-row">
        <div class="logo" [routerLink]="'/'">
          <svg>
            <use [attr.xlink:href]="svgsetIcon('logo-header')"></use>
          </svg>
        </div>

        <div class="navigation">
          <ul class="menu footer-menu">
            <ng-container *ngIf="!me; else menu_auth">
            <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['']" [routerLinkActiveOptions]="{ exact: true }" class="menu_link">dating</a></li>
            <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['privacy']" class="menu_link">privacy</a></li>
            <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['terms-of-use']" class="menu_link">terms</a></li>
            <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['help']" class="menu_link">support</a></li>
            </ng-container>
          </ul>
        </div>

        <div class="top" href="#top"></div>
      </div>

      <div class="f-row">
        <div class="copy">© viksi666 2017 - {{ year }}</div>
      </div>
    </div>
  </footer>
</div>

<ng-template #menu_auth>
  <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['feed']" class="menu_link">Лента</a></li>
  <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['chat']" class="menu_link">Чат</a></li>
  <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['sympathies']" class="menu_link">Симпатии</a></li>
  <li class="menu_item"><a [routerLinkActive]="['is-active']" [routerLink]="['desired']" class="menu_link">Желанные</a></li>
</ng-template>
