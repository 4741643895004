<div class="attachment-uploader">
  <div class="dropzone dropzone-container" *ngIf="!cover"
       [class.no-cover]="!cover"
       [dropzone]="dropzoneConfig"
       (init)="onUploadInit($event)"
       (sending)="onUploadStart($event)"
       (error)="onUploadError($event)"
       (success)="onUploadSuccess($event)">
    <div class="dz-default dz-message">
      <ng-template [ngIf]="!cover_uploading">
        <app-icon
          class="attach-icon"
          [width]="30"
          [height]="30"
          [icon]="'plus'">
        </app-icon>
      </ng-template>

      <ng-template [ngIf]="cover_uploading">
        <spinner1></spinner1>
      </ng-template>
    </div>
  </div>

  <div class="dropzone dropzone-container" *ngIf="cover">
    <div class="dz-default dz-message">
      <ng-template [ngIf]="!cover_uploading">
        <img [src]="cover.url" alt="" class="cover-img">
      </ng-template>
    </div>
    <div class="delete-wrap" (click)="onRemove()">
      <app-icon [height]="30" [width]="30" [icon]="'modal-close'" class="delete-icon"></app-icon>
    </div>
  </div>
</div>
