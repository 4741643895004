import {Component, OnInit} from '@angular/core';
import {IconsService} from './services/icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public constructor(
    private icons: IconsService,
    ) {}


  public ngOnInit(): void {
    this.icons.inject();
  }
}
