import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthModalComponent } from '@app/components/auth-modal/auth-modal.component';
import { MobileModalModule } from '@app/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@app/components/modal/modal.module';
import { UiButtonComponent } from '@app/components/shared/ui-button/ui-button.component';
import {
  OnUiCover,
  UiCoverComponent,
} from '@app/components/shared/ui-cover/ui-cover.component';
import { environment } from '@env/environment';
import { Device, DEVICE } from '@le2xx/ngx-toolkit-device';
import { ChangableComponent } from '@models/changable.component';
import { Subject, Subscription, timer } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Account, AccountOnlineStatusEnum } from 'viksi-models';
import { AccountService, AuthService } from '../../services';

const oneMinute = 60 * 1000;
const recreate_interval = 180 * oneMinute; // интервал повторной генерации токенов = 180 минут = 3 часа
const online_status_interval = 5 * oneMinute; // интервал оповещения о статусе 'онлайн' = 5 минут

const access_token_key = environment.access_token_key || 'APP_AUTH_ACCESS';

@Component({
  selector: 'app-me',
  standalone: true,
  imports: [
    CommonModule,
    ModalModule,
    MobileModalModule,
    UiButtonComponent,
    AuthModalComponent,
    UiCoverComponent,
  ],
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeComponent extends ChangableComponent implements OnInit {
  public me_id: string;
  public me: Account;
  public isModalOpen: boolean;
  public notFilled: boolean;

  protected recreate_interval_key = `${access_token_key}_RECREATED_AT`;
  protected recreate_interval = recreate_interval;
  protected online_status_interval = online_status_interval;

  private lastAccessToken: string;
  private reloginInterval;
  private recreateInterval;
  public cover: OnUiCover;

  constructor(
    protected authService: AuthService,
    protected accountService: AccountService,
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    @Inject(DEVICE) public device: Device
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    // me$ порождается сразу при загрузке приложения
    // state$ порождается при изменении me$

    this.authService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => {
      this.me_id = me?.id;
      this.updateMe();
      this.detectChanges();
    });

    this.authService.state$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state) => {
        switch (state) {
          case 'signedIn':
            this.updateMe();
            break;

          case 'guest':
          case 'signedUp':
          case 'signedOut':
            this.updateMe();
            break;

          default:
            // debugger;
            break;
        }
      });
    this.initWatchdog();
  }

  public openSignInModal() {
    this.isModalOpen = true;
  }

  public get userpicUrl(): string {
    return this.me.userpic?.sm?.url;
  }

  public get userClass(): any {
    const classes = [];

    const purpose = this.me?.purpose?.code;
    if (purpose) {
      classes.push(`purpose-${purpose}`);
    }

    return classes;
  }

  public signOut() {
    this.sendOfflineStatus();
    setTimeout(() => this.authService.logOut(), 500);
  }

  protected initWatchdog() {
    this.reloginInterval = setInterval(() => {
      this.autoReLogin();
    }, 10 * 1000);

    this.recreateInterval = setInterval(() => {
      this.autoRecreateToken();
    }, this.recreate_interval);

    // отправить статус онлайн сейчас и через интервал
    timer(1500, this.online_status_interval)
      .pipe(
        filter(() => !!this.me),
        tap(() => this.sendOnlineStatus()),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  /**
   * Обновить информацию о пользователе, если в другой вкладке браузера была выполнена другая авторизация
   */
  protected autoReLogin() {
    const access = this.authService.accessToken;
    if (this.lastAccessToken !== access) {
      // если токен изменился (токен устарел или пользователь залогинился/разлогинился в другой вкладке браузера)
      if (!access) {
        console.log(
          'MeComponent:autoReLogin',
          'accessToken is absent -> logout'
        );
        this.authService.logOut();
      } else {
        console.log(
          'MeComponent:autoReLogin',
          'accessToken is changed, reload Account'
        );
        const account = this.authService.decodeAccessToken(access);
        if (account) {
          console.log('MeComponent:autoReLogin', 'success');
          this.lastAccessToken = access;
          this.authService.refreshAccount(account.id);
          this.sendOnlineStatus();
        } else {
          // если токен сбойный
          console.log('MeComponent:autoReLogin', 'fail -> logout');
          this.authService.logOut();
          this.sendOfflineStatus();
        }
      }
    }
  }

  /**
   * проверять наличие и свежесть токенов, при необходмости запросить новые
   */
  protected autoRecreateToken() {
    console.log('MeComponent.autoRecreateToken');
    try {
      if (!this.authService.refreshToken) {
        return;
      }

      const lastRecreatedAt = localStorage.getItem(this.recreate_interval_key);
      if (
        !this.authService.accessToken ||
        !lastRecreatedAt ||
        Date.now() - new Date(lastRecreatedAt).getTime() >
          this.recreate_interval
      ) {
        this.authService.recreateToken();
        localStorage.setItem(this.recreate_interval_key, new Date().toString());
      }
    } catch (err) {
      console.error('MeComponent.autoRecreateToken', err);
    }
  }

  protected updateMe() {
    if (!this.me_id) {
      this.me = null;
      localStorage.removeItem(this.recreate_interval_key);
      return;
    }

    this.accountService
      .getAccountById(this.me_id, ['full'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((account) => {
        this.me = account;
        this.cover = {
          img: this.userpicUrl || null,
          form: 'circle',
          type: 'avatar',
        };
        this.detectChanges();
        this.sendOnlineStatus();
      });
  }

  /** Оповещение о статусе 'онлайн' */
  protected sendOnlineStatus() {
    if (!this.me) {
      return;
    }
    this.accountService
      .updateStatus({ status: AccountOnlineStatusEnum.online })
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  /** Оповещение о статусе 'оффлайн' */
  protected sendOfflineStatus() {
    this.accountService
      .updateStatus({ status: AccountOnlineStatusEnum.offline })
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  public checkProfile() {
    if (this.me.purpose) {
      this.isModalOpen = false;
      this.navigateTo('profile');
    } else {
      this.isModalOpen = true;
      this.notFilled = true;
    }
  }

  public checkStep() {
    this.setTimeout(() => {
      if (this.me.purpose) {
        this.isModalOpen = false;
        this.navigateTo('feed');
        this.detectChanges();
      } else {
        this.notFilled = true;
        this.detectChanges();
      }
    }, 1000);
  }

  public onClose(e: boolean) {
    this.isModalOpen = false;

    if (e) {
      this.updateMe();
      this.navigateTo('feed');
    }
  }

  public navigateTo(page: string) {
    this.router.navigate([page]);
  }
}
