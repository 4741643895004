import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { response } from 'express';
import geodist from 'geodist';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  FindAccountsFilter,
  PagedResults,
  Pagination,
  Account,
  AccountRoleEnum,
  AccountSegmentEnum,
  AccountSettings,
  DoneFailResponse,
  HotStatusRequest,
  GeoLocationRequest,
  StatusRequest,
  AccountComplaintRequest,
  AccountTrackRequest,
  ProfileVerification,
  ProfileVerificationRequest,
  CreateChatThreadResponse,
  AccountOnlineStatusEnum,
  LikesResponse,
  PhoneChangeResponse,
  PhoneChangeRequest,
  PhoneChangeVerificationRequest,
} from 'viksi-models';

interface GeoPoint {
  geo_latitude: number;
  geo_longitude: number;
}

export function calcDistance(a: GeoPoint, b: GeoPoint) {
  if (
    !a ||
    !b ||
    !a.geo_latitude ||
    !b.geo_longitude ||
    !a.geo_latitude ||
    !b.geo_longitude
  ) {
    return null;
  }

  const distanceMeters = geodist(
    { lat: b.geo_latitude, lon: b.geo_longitude },
    { lat: a.geo_latitude, lon: a.geo_longitude },
    { unit: 'meters' }
  );

  return +(distanceMeters / 1000).toFixed(2);
}

export const onlineStatuses = [
  AccountOnlineStatusEnum.online,
  AccountOnlineStatusEnum.busy,
  AccountOnlineStatusEnum.away,
];

export type AccountDetailsType =
  | keyof Account
  | 'min'
  | 'full'
  | 'userpic:xs'
  | 'userpic:sm'
  | 'userpic:md'
  | 'userpic:sm:md';

/** https://ipwhois.io/ru/documentation */
export interface IPWhoisResponse {
  ip: string;
  success: boolean;
  type: string;
  continent: string;
  continent_code: string;
  country: string;
  country_code: string;
  country_flag: string;
  country_capital: string;
  country_phone: string;
  country_neighbours: string;
  region: string;
  city: string;
  latitude: number;
  longitude: number;
  asn: string;
  org: string;
  isp: string;
  timezone: string;
  timezone_name: string;
  timezone_dstOffset: number;
  timezone_gmtOffset: number;
  timezone_gmt: string;
  currency: string;
  currency_code: string;
  currency_symbol: string;
  currency_rates: number;
  currency_plural: string;
  completed_requests: number;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  public static accountRoute(
    account: Partial<Account>,
    extra: string[] = []
  ): string[] {
    return account ? ['/u', account.id, ...extra] : null;
  }

  public static getUserName(user: Partial<Account>): string {
    if (!user) {
      return '';
    }
    const first_names = user.first_name
      ? user.first_name.split(/\s+/).filter((s) => s.length)
      : '';
    const last_name = user.last_name ? user.last_name.trim() : '';

    return [first_names[0] || '', last_name].filter((s) => s.length).join(' ');
  }

  public static getUserFullName(user: Partial<Account>): string {
    if (!user) {
      return '';
    }

    return [user.first_name, user.last_name]
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim();
  }

  public static hasRole(
    user: Partial<Account>,
    role: AccountRoleEnum
  ): boolean {
    return user && (user.roles || []).includes(role);
  }

  public static hasSegment(
    user: Partial<Account>,
    segment: AccountSegmentEnum
  ): boolean {
    return user && !!(user.segments || []).find((s) => s.segment === segment);
  }

  public getAccountById(
    account_id: string,
    details: AccountDetailsType[]
  ): Observable<Account> {
    const url = `${environment.account_url}/account/${account_id}`;
    const params = new HttpParams().set('details', details.join(','));
    return this.http
      .get<Account>(url, { params, observe: 'response' })
      .pipe(map(this.handleAccountResponse));
  }

  public addSegment(segment: AccountSegmentEnum): Observable<Account> {
    const url = `${environment.account_url}/account/segment/${segment}`;
    return this.http
      .put<Account>(url, {}, { observe: 'response' })
      .pipe(map(this.handleAccountResponse));
  }

  public removeSegment(segment: AccountSegmentEnum): Observable<Account> {
    const url = `${environment.account_url}/account/segment/${segment}`;
    return this.http
      .delete<Account>(url, { observe: 'response' })
      .pipe(map(this.handleAccountResponse));
  }

  /** Зафиксировать абстрактный показатель (затрекать) */
  public trackAccount(data: AccountTrackRequest): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/track`;
    return this.http.post<DoneFailResponse>(url, data, { observe: 'body' });
  }

  /** Запросить задание для верификации */
  public getProfileVerification(): Observable<ProfileVerification> {
    const url = `${environment.account_url}/account/verification`;
    return this.http.post<ProfileVerification>(url, {}, { observe: 'body' });
  }

  /** Отправить запрос на верификацию */
  public verifyProfile(
    data: ProfileVerificationRequest
  ): Observable<ProfileVerification> {
    const url = `${environment.account_url}/account/verify`;
    return this.http.post<ProfileVerification>(url, data, { observe: 'body' });
  }

  /** ПОлучить настройки пользователя */
  public getAccountSettings(): Observable<AccountSettings> {
    const url = `${environment.account_url}/settings`;
    return this.http
      .get<AccountSettings>(url, { observe: 'body' })
      .pipe(map((response) => new AccountSettings(response)));
  }

  /** Сменить номер телефона пользователя */
  public changeMobilePhone(
    mobile_phone: number
  ): Observable<PhoneChangeResponse> {
    const values: PhoneChangeRequest = {
      mobile_phone,
    };
    const url = `${environment.account_url}/settings/mobile-phone/change`;
    return this.http.post<PhoneChangeResponse>(url, values, {
      observe: 'body',
    });
  }

  /** Подтвердить смену номера телефона пользователя */
  public changeMobilePhoneVerify(
    verification_id: string,
    verification_code: string
  ): Observable<DoneFailResponse> {
    const values: PhoneChangeVerificationRequest = {
      verification_id,
      verification_code,
    };
    const url = `${environment.account_url}/settings/mobile-phone/verify`;
    return this.http.put<DoneFailResponse>(url, values, { observe: 'body' });
  }

  /** Удалить аккаунт */
  public removeAccount(): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/delete`;
    return this.http.delete<DoneFailResponse>(url, { observe: 'body' });
  }

  /** Список пользователей по специческим условиям поиска */
  public findAccounts(
    filter: Partial<FindAccountsFilter>,
    pagination: Pagination,
    details: AccountDetailsType[]
  ): Observable<PagedResults<Account>> {
    return this.findAccounts2(filter, pagination, details);
  }

  /** Список пользователей по специческим условиям поиска */
  public findAccounts2(
    filter: Partial<FindAccountsFilter>,
    pagination: Pagination,
    details: AccountDetailsType[]
  ): Observable<PagedResults<Account>> {
    const url = `${environment.account_url}/accounts`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('details', details.join(','));
    return this.http
      .post<PagedResults<Account>>(url, filter, { params, observe: 'response' })
      .pipe(map(this.handleAccountsResponse));
  }

  /** Список пользователей по набору предустановленных фильтров */
  public findAccountsByPresets(
    filterSet: string[],
    filter: Partial<FindAccountsFilter>,
    pagination: Pagination,
    details: AccountDetailsType[]
  ): Observable<PagedResults<Account>> {
    return this.findAccounts2ByPresets(filterSet, filter, pagination, details);
  }

  /** Список пользователей по набору предустановленных фильтров */
  public findAccounts2ByPresets(
    filterSet: string[],
    filter: Partial<FindAccountsFilter>,
    pagination: Pagination,
    details: AccountDetailsType[]
  ): Observable<PagedResults<Account>> {
    const url = `${environment.account_url}/accounts`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter-set', filterSet.join(','))
      .set('details', details.join(','));
    return this.http
      .post<PagedResults<Account>>(url, filter, { params, observe: 'response' })
      .pipe(map(this.handleAccountsResponse));
  }

  /** Поставить лайк пользователю */
  public addLike(id: string): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/${id}/like`;
    return this.http.put<DoneFailResponse>(url, {}, { observe: 'body' });
  }

  /** Удалить лайк */
  public deleteLike(id: string): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/${id}/like`;
    return this.http.delete<DoneFailResponse>(url, { observe: 'body' });
  }

  /** Удалить лайк, взаимный лайк и чат */
  public deleteLikesAndChat(id: string): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/${id}/likes-and-chat`;
    return this.http.delete<DoneFailResponse>(url, { observe: 'body' });
  }

  /** Количество лайков */
  public countLikes(): Observable<LikesResponse> {
    const url = `${environment.account_url}/likes`;
    return this.http.get<LikesResponse>(url, { observe: 'body' });
  }

  /** Определить координаты по IP */
  public getCoordinatesByIP(): Observable<IPWhoisResponse> {
    const url = `https://ipwhois.app/json/`;
    return this.http.get<IPWhoisResponse>(url, { observe: 'body' });
  }

  public updateAccount(
    account_id: string,
    account: Partial<Account>
  ): Observable<Account> {
    const url = `${environment.account_url}/account/${account_id}`;
    return this.http
      .put<Account>(url, account, { observe: 'body' })
      .pipe(map((response) => new Account(response)));
  }

  /** Обновить гео-локацию */
  public updateGeoLocation(
    data: GeoLocationRequest
  ): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/geo`;
    return this.http.put<DoneFailResponse>(url, data, { observe: 'body' });
  }

  /** Обновить статус 'онлайн' */
  public updateStatus(data: StatusRequest): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/status`;
    return this.http.put<DoneFailResponse>(url, data, { observe: 'body' });
  }

  /** Установить горячий статус */
  public setHotStatus(data: HotStatusRequest): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/hot-status`;
    return this.http.put<DoneFailResponse>(url, data, { observe: 'body' });
  }

  /** Отменить горячий статус */
  public cancelHotStatus(): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/hot-status`;
    return this.http.delete<DoneFailResponse>(url, { observe: 'body' });
  }

  /** Отметить пользователя как просмотренного */
  public addAccountView(account_id: string): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/${account_id}/view`;
    return this.http.post<DoneFailResponse>(url, {}, { observe: 'body' });
  }

  public createChat(
    companion_id: string
  ): Observable<CreateChatThreadResponse> {
    const url = `${environment.account_url}/account/${companion_id}/chat`;
    return this.http.post<CreateChatThreadResponse>(
      url,
      {},
      { observe: 'body' }
    );
  }

  public removeLikesAndChat(
    companion_id: string
  ): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/${companion_id}/likes-and-chat`;
    return this.http.delete<DoneFailResponse>(url, { observe: 'body' });
  }

  /** Отправить жалобу на пользователя */
  public sendComplaint(
    account_id: string,
    complaint: AccountComplaintRequest
  ): Observable<DoneFailResponse> {
    const url = `${environment.account_url}/account/${account_id}/complaint`;
    return this.http.post<DoneFailResponse>(url, complaint, {
      observe: 'body',
    });
  }

  private handleAccountResponse(response: HttpResponse<Account>): Account {
    return new Account(response.body);
  }

  private handleAccountsResponse(
    response: HttpResponse<PagedResults<Account>>
  ): PagedResults<Account> {
    return {
      results: response.body.results.map((i) => new Account(i)),
      pagination: response.body.pagination,
    };
  }
}
