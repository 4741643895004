import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '..';
import * as GeneralActions from '../actions/general.actions';

export interface State {
  httpError: any;
}

const initialState: State = {
  httpError: null,
};

const generalReducer = createReducer(
  initialState,
  on(GeneralActions.setHttpError, (state, { error }) => ({
    ...state,
    httpError: error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return generalReducer(state, action);
}

const thisFeatureKey = 'general';
export const generalFeatureKey = thisFeatureKey;

/* Selectors */
export const getLastHttpError = (state: AppState) =>
  state[thisFeatureKey].httpError;
