import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconsService extends DestroyableComponent {
  constructor(private http: HttpClient) {
    super();
  }

  public static svgsetIconUrl(icon: string): string {
    return `#${icon}`;
  }

  inject() {
    this.http
      .get(this.assetsUrl('/assets/icons/svgset.svg'), {
        responseType: 'text',
        withCredentials: false,
        observe: 'response',
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response.body) {
          const div = document.createElement('div');
          div.setAttribute('style', 'display: none;');
          div.innerHTML = response.body;
          document.getElementsByTagName('body')[0].appendChild(div);
        }
      });
  }
}
